import React from 'react';
import { Box, Button, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ChevronRight, VisibilityOff, WarningRounded } from '@material-ui/icons';
import { unpackToDate } from '@campfire/hot-date';
import { DraggableListItem, DraggableListItemHandle } from '@campfire/draggable-list';
import { GetTaskDashboard_vm_tasks as DashTaskType } from '../Dashboard/__generated__/GetTaskDashboard';
import { TaskListGetCake_vm_cake_allTasks as CakeTaskType } from '../TasksList/__generated__/TaskListGetCake';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { TaskBadge } from '../TaskBadge';

type TaskType = DashTaskType | CakeTaskType;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '30px',
      paddingLeft: '30px',
      paddingTop: '20px',
      paddingBottom: '20px',
      border: '1px solid #54545440',
      borderRadius: '4px',
      justifyContent: 'space-between',
      cursor: 'pointer',
      backgroundColor: theme.color.white.neutral50,
      flex: 1,
    },
    taskContainer: {
      '& .titleContainer': {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      },
      '& .title': {
        fontSize: '24px',
        fontWeight: 900,
        color: theme.color.grey.neutral400,
      },
      '& .description': {
        fontWeight: 400,
        color: theme.color.grey.neutral400,
        fontSize: '0.9rem',
        marginTop: '6px',
      },
      '& .badge': {
        fontSize: '.74em',
        fontWeight: 500,
        padding: 8,
        paddingLeft: 12,
        paddingRight: 12,
        borderRadius: 4,
        backgroundColor: '#D9D9D9',
        color: theme.color.grey.neutral400,
      },
      '& .badgeContainer': {
        display: 'flex',
        marginTop: '15px',
      },
      '& .expiry': {
        color: '#92400E',
        backgroundColor: '#FFFBEB',
        display: 'flex',
        alignItems: 'center',
      },
      '& .expiryIcon': {
        color: '#FBBF24',
        fontSize: '12px',
        marginRight: '8px',
      },
    },
    button: {
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: 'unset',
      width: '36px',
      color: 'white',
      backgroundColor: '#9E9E9E',
    },
  })
);

interface TaskProps {
  task: TaskType;
  onClick: () => void;
}

export function Task({ onClick, task }: TaskProps) {
  const classes = useStyles();
  const { theme } = useCampfireTheme();

  const isExpiring =
    'expiryDate' in task
      ? task.expiryDate &&
        (unpackToDate(task.expiryDate)
          .diffNow('weeks')
          .toObject().weeks || 0) < 2
      : false;

  return (
    <Box className={classes.container} onClick={onClick}>
      <Box className={classes.taskContainer}>
        <Box className='titleContainer'>
          <Typography
            variant={'h6'}
            noWrap
            style={{ color: theme.color.grey.neutral400, fontWeight: 800, letterSpacing: '-0.02em' }}
          >
            {task.title}
          </Typography>
          {task.__typename === 'VOLUNTEER_TaskType' && task?.metadata && task?.metadata.totalPendingApprovals ? (
            <TaskBadge number={task.metadata.totalPendingApprovals} variant='warning' />
          ) : null}
        </Box>
        <Typography className='description'>{task.description}</Typography>
        <Box className={'badgeContainer'} style={{ gap: 9 }}>
          {!task.automateApproval && <Box className='badge'>Manual Review</Box>}
          {(task.programs.length > 0 || task.roles.length > 0) && <Box className='badge'>Restricted Visibility</Box>}
          {isExpiring && (
            <Box className='badge expiry'>
              <WarningRounded className='expiryIcon' />
              Expiring Soon
            </Box>
          )}
          {task.recurrences.length > 0 && <Box className='badge'>Expiry Enabled</Box>}
          {task.allowUpdate && <Box className='badge'>Updating Allowed</Box>}
          {task.applicantsOnly && <Box className='badge'>Applicants Only</Box>}
          {task.isHidden && (
            <Box className='badge' display={'flex'} alignItems={'center'}>
              <VisibilityOff style={{ fontSize: '15px', paddingRight: '5px' }} /> Hidden
            </Box>
          )}
        </Box>
      </Box>
      <Button variant='contained' disableElevation className={classes.button}>
        <ChevronRight />
      </Button>
    </Box>
  );
}

type DraggableTaskProps = TaskProps & {
  index: number;
};

export function DraggableTask({ onClick, task, index }: DraggableTaskProps) {
  return (
    <DraggableListItem id={`${index}`} index={index}>
      <Box display='flex' flex='1 1 auto' position='relative'>
        <Box marginRight='2rem' marginLeft='1rem'>
          <DraggableListItemHandle />
        </Box>

        <Box display='flex' flex='1 1 auto' alignContent='center' alignItems='center'>
          <Task onClick={onClick} task={task} />
        </Box>
      </Box>
    </DraggableListItem>
  );
}
