import React, { useState } from 'react';
import { List, ListSubheader } from '@material-ui/core';
import { useBetween } from 'use-between';
import { SectionHeader } from '../../common/SectionHeader';
import { SectionInstruction } from '../../common/SectionInstruction';
import { VolunteerTaskAddDialog } from './VolunteerTaskAddDialog';
import { SectionCollapsible } from '../../common/SectionCollapsible';
import { VolunteerTaskItem } from './VolunteerTaskItem';

export interface AdditionalTask {
  taskId: string;
  title: string;
  cake: {
    cakeType: string;
  };
}

interface VolunteerTasksSectionProps {
  tasks: Array<AdditionalTask>;
  userId: string;
  profileId: string;
  refetch?: () => void;
}

const useVolunteerAdditionalTasksSectionState = () => {
  const [selectedAdditionalTask, setSelectedAdditionalTask] = useState<AdditionalTask | undefined>();
  const [refetchAdditionalTasks, setRefetchAdditionalTasks] = useState<boolean>(false);

  return {
    refetchAdditionalTasks,
    setRefetchAdditionalTasks,
    selectedAdditionalTask,
    setSelectedAdditionalTask,
  };
};

export const useSharedVolunteerAdditionalTasksSectionState = () => useBetween(useVolunteerAdditionalTasksSectionState);

export const VolunteerAdditionalTasksSection = (props: VolunteerTasksSectionProps) => {
  const { tasks, userId, profileId, refetch } = props;
  const { selectedAdditionalTask, setSelectedAdditionalTask } = useSharedVolunteerAdditionalTasksSectionState();
  return (
    <>
      <SectionCollapsible
        sectionHeader={<SectionHeader title='Incomplete Tasks' />}
        instruction={<SectionInstruction>{`Tasks a volunteer has not yet completed`}</SectionInstruction>}
        collapseContent={
          <List disablePadding dense>
            <ListSubheader>{`Tasks added after volunteer was onboarded`}</ListSubheader>
            {tasks.map((task) => (
              <VolunteerTaskItem task={task} onClick={() => setSelectedAdditionalTask(task)} />
            ))}
          </List>
        }
        expandText='View tasks'
        idStub='tasks'
      />

      {selectedAdditionalTask ? (
        <VolunteerTaskAddDialog
          open
          onClose={() => setSelectedAdditionalTask(undefined)}
          taskId={selectedAdditionalTask.taskId}
          userId={userId}
          profileId={profileId}
          refetch={refetch}
        />
      ) : null}
    </>
  );
};
