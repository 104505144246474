import { Field } from '@campfire/field';
import { unpackToTime } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { MultiSelect } from '@campfire/multi-select';
import { Select } from '@campfire/select';
import { TabletButton } from '@campfire/tablet-button';
import { TimePicker } from '@campfire/time-picker';
import {
  Box,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { AddCircle, Save, ThumbDown, ThumbUp } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import { Field as FormikField, FieldProps as FormikFieldProps, Form, Formik, getIn, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { FileUploadGallery, FugFile, UploadedFile } from '@campfire/file-upload-gallery';
import { AlertCard, getAlertCardColors } from '../../../../../../../common/cards/alert-card/AlertCard';
import { FormikErrorFocus } from '../../../../../../../common/form/FormikErrorFocus';
import { assertNever } from '../../../../../../../common/functions/assert-never';
import { scrollIntoView } from '../../../../../../../common/functions/scroll-functions';
import { useSnackbar } from '../../../../../../../global/config/useSnackbar';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { Avatar } from '../../../../../../general/user-profile/components/Avatar';
import { useSaveSessionReportFetch } from '../../../activity-timeline-actions';
import { ActivityTimelinePastPublishedRoster } from '../../../__generated__/ActivityTimelinePastPublishedRoster';
import { ActivityTimelinePastReportSession } from '../../../__generated__/ActivityTimelinePastReportSession';
import { ActivityTimelinePastSessionReport } from '../../../__generated__/ActivityTimelinePastSessionReport';
import { DropdownOption } from '../../../__generated__/DropdownOption';
import { AddOtherAttendeeDialog } from './AddOtherAttendeeDialog';
import { AddVolunteersDialog } from './AddVolunteersDialog';
import { MultiSelectVolunteersDialogVolunteerType } from './MultiSelectVolunteersDialog';
import {
  getEndpointParsedSessionReport,
  getInitialSessionReportFormValues,
  getValidationSchema,
  SessionReportFormValuesType,
  getBase64FromAttachment,
} from './session-report-helpers';
import { AddOtherVolunteersDialog } from '../../../../../../general/vol-dashboard/vol-dashboard-my-elements/main-section/my-activities/CICO/AddOtherVolunteersDialog';
import { VolunteerType } from '../../../../../../general/vol-dashboard/vol-dashboard-my-elements/main-section/my-activities/CICO/MyActivityCheckInDialog';

type SessionReportFieldProps = {
  name: string;
  description: string;
  optional?: boolean;
  children?: React.ReactNode;
} & GridProps;

const SessionReportField = React.memo((props: SessionReportFieldProps) => {
  const { name, description, optional, children } = props;
  const { theme } = useCampfireTheme();

  return (
    <Grid
      container
      style={{
        paddingTop: 16,
        paddingBottom: 16,
        borderBottom: `1px solid ${theme.color.grey.neutralBrand200}`,
      }}
    >
      <Grid item xs={12} md={4} style={{ marginRight: 16 }}>
        <Typography
          style={{
            marginBottom: 8,
          }}
        >
          {name}
          {`${optional === false ? ' *' : ''}`}
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          {description}
        </Typography>
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  );
});

interface SessionReportFormProps {
  onSubmitSuccess: (res: { activityReportId: string; sessionReportId: string }) => void;
  session: ActivityTimelinePastReportSession;
  activityDate: string;
  publishedRoster?: ActivityTimelinePastPublishedRoster;
  sessionReport?: ActivityTimelinePastSessionReport;
  onCancelClick: () => void;
}

const SessionReportFormV2 = React.memo((props: SessionReportFormProps) => {
  const { session, activityDate, publishedRoster, onSubmitSuccess, onCancelClick, sessionReport } = props;
  const saveSessionReport = useSaveSessionReportFetch();

  const initialValues = useMemo(() => getInitialSessionReportFormValues(session, sessionReport, publishedRoster), [
    session,
  ]);

  const validationSchema = useMemo(() => getValidationSchema(session, sessionReport), [session, sessionReport]);

  const { setSnackbar } = useSnackbar();

  const handleSubmit = (values: SessionReportFormValuesType) => {
    const parsedSessionReport = getEndpointParsedSessionReport(session, activityDate, values);
    saveSessionReport
      .run(parsedSessionReport)
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to submit session report',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Session report submitted',
          variant: 'success',
        });

        if (onSubmitSuccess) {
          onSubmitSuccess(res.data.data);
        }
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to submit session report',
          variant: 'error',
        })
      );
  };

  return (
    <Formik<SessionReportFormValuesType>
      initialValues={initialValues}
      initialStatus={{
        startTimeInvalidRange: false,
        endTimeInvalidRange: false,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <CardContent style={{ paddingLeft: 8 }}>
          {((sessionReport && !sessionReport.allowCICO) || (!sessionReport && !session.activity.allowCICO)) && (
            <SessionReportField name='Time' description='What time did the session begin and end?'>
              <SessionTimeSection sessionStartTime={session.startTime} sessionEndTime={session.endTime} />
            </SessionReportField>
          )}
          {(sessionReport && !sessionReport.allowCICO) || (!sessionReport && !session.activity.allowCICO) ? (
            <SessionAttendanceSection publishedRoster={publishedRoster} session={session} />
          ) : (
            ((sessionReport && sessionReport.allowCICO) || (!sessionReport && session.activity.allowCICO)) && (
              <SessionCICOSection publishedRoster={publishedRoster} session={session} />
            )
          )}

          {session?.reportType === null
            ? ''
            : session.reportType?.items
                .sort((a, b) => a.order - b.order)
                .map((reportTypeItem) => {
                  if (reportTypeItem.__typename === 'VOLUNTEER_ReportTypeHeadingType') {
                    return (
                      <Box
                        key={reportTypeItem.reportTypeItemId}
                        paddingTop={4}
                        paddingBottom={1}
                        display='inline-block'
                      >
                        <Typography style={{ fontWeight: 600 }}>{reportTypeItem.heading}</Typography>
                      </Box>
                    );
                  }
                  if (reportTypeItem.__typename === 'VOLUNTEER_ReportTypeFieldType') {
                    const { field } = reportTypeItem;
                    const sessionReportFieldBaseProps: SessionReportFieldProps = {
                      key: reportTypeItem.reportTypeItemId,
                      name: field.name,
                      optional: reportTypeItem.optional,
                      description: field.description,
                    };
                    const formikFormName = `fields[${field.fieldId}].value`;
                    const label = field.name;
                    const required = !reportTypeItem.optional;

                    if (field.__typename === 'VOLUNTEER_BooleanFieldType') {
                      return (
                        <SessionReportField {...sessionReportFieldBaseProps}>
                          <ThumbUpDownSection name={formikFormName} label={label} required={required} />
                        </SessionReportField>
                      );
                    }

                    if (field.__typename === 'VOLUNTEER_CheckboxFieldType') {
                      return (
                        <SessionReportField {...sessionReportFieldBaseProps}>
                          <CheckboxSection name={formikFormName} label={label} required={required} />
                        </SessionReportField>
                      );
                    }

                    if (field.__typename === 'VOLUNTEER_DropdownFieldType' && field.isAllowMultiple === true) {
                      return (
                        <SessionReportField {...sessionReportFieldBaseProps}>
                          <MultiSelect
                            name={formikFormName}
                            items={field.dropdownOptions.map((x) => ({
                              label: x.name,
                              value: x.dropdownFieldOptionId,
                            }))}
                          />
                        </SessionReportField>
                      );
                    }

                    if (field.__typename === 'VOLUNTEER_DropdownFieldType') {
                      return (
                        <SessionReportField {...sessionReportFieldBaseProps}>
                          <Select
                            placeholder='Choose one'
                            required={required}
                            includeEmpty
                            fullWidth
                            variant='outlined'
                            name={formikFormName}
                            label={label}
                            items={field.dropdownOptions}
                            renderFunction={(dropdownOption: DropdownOption) => dropdownOption.name}
                            valueFunction={(dropdownOption: DropdownOption) => dropdownOption.dropdownFieldOptionId}
                          />
                        </SessionReportField>
                      );
                    }

                    if (field.__typename === 'VOLUNTEER_NumericFieldType') {
                      return (
                        <SessionReportField {...sessionReportFieldBaseProps}>
                          <Field
                            required={required}
                            fullWidth
                            variant='outlined'
                            type='number'
                            label={label}
                            name={formikFormName}
                          />
                        </SessionReportField>
                      );
                    }

                    if (field.__typename === 'VOLUNTEER_RatingFieldType') {
                      return (
                        <SessionReportField {...sessionReportFieldBaseProps}>
                          <RatingSection name={formikFormName} label={label} />
                        </SessionReportField>
                      );
                    }

                    if (field.__typename === 'VOLUNTEER_TextFieldType') {
                      return (
                        <SessionReportField {...sessionReportFieldBaseProps}>
                          {field.isLongText ? (
                            <Field
                              required={required}
                              fullWidth
                              variant='outlined'
                              label={label}
                              name={formikFormName}
                              multiline
                              rows={4}
                            />
                          ) : (
                            <Field
                              required={required}
                              fullWidth
                              variant='outlined'
                              label={label}
                              name={formikFormName}
                            />
                          )}
                        </SessionReportField>
                      );
                    }

                    if (field.__typename === 'VOLUNTEER_TimeFieldType') {
                      return (
                        <SessionReportField {...sessionReportFieldBaseProps}>
                          <TimePicker
                            required={required}
                            fullWidth
                            inputVariant='outlined'
                            label={label}
                            name={formikFormName}
                          />
                        </SessionReportField>
                      );
                    }

                    if (field.__typename === 'VOLUNTEER_AttachmentFieldType') {
                      return (
                        <SessionReportField {...sessionReportFieldBaseProps}>
                          <AttachmentSection name={formikFormName} label={label} />
                        </SessionReportField>
                      );
                    }

                    return assertNever(field.__typename);
                  }
                  return assertNever(reportTypeItem);
                })}

          <SessionReportField name='Journal' description='How did things go?'>
            <Box width={1} height={1}>
              <Field
                variant='outlined'
                multiline
                fullWidth
                rows={4}
                name='journal'
                label='Journal Entry'
                shrink
                placeholder='Comments, stories, and notes from this session'
              />
            </Box>
          </SessionReportField>
        </CardContent>
        <CardActions>
          <Box
            paddingY={2}
            paddingX={1}
            width={1}
            display='flex'
            justifyContent='flex-end'
            alignContent='center'
            alignItems='center'
          >
            <HoverText
              data-track='actCnl-report-card-cancel-report'
              color='error'
              hoverColor='error'
              variant='body2'
              onClick={onCancelClick}
              style={{ paddingRight: 16 }}
            >
              {'Cancel'}
            </HoverText>

            <TabletButton
              data-track='actCnl-report-card-submit-report'
              type='submit'
              variant='contained'
              color='primary'
              size='medium'
              disabled={saveSessionReport.isLoading}
              endIcon={<Save />}
            >
              {'Save'}
            </TabletButton>
          </Box>
        </CardActions>
        <FormikErrorFocus />
      </Form>
    </Formik>
  );
});

const SessionTimeSection = ({
  sessionStartTime,
  sessionEndTime,
}: {
  sessionStartTime: string;
  sessionEndTime: string;
}) => {
  const { errors, touched, setStatus, values, status } = useFormikContext<SessionReportFormValuesType>();
  const validateStartTime = getIn(touched, 'startTime') && !getIn(errors, 'startTime');
  const validateEndTime = getIn(touched, 'endTime') && !getIn(errors, 'endTime');
  const { lightTextColor } = getAlertCardColors('warning');
  const { lightTextColor: errorTextColor } = getAlertCardColors('urgent');
  const { isMd } = useCampfireTheme();

  useEffect(() => {
    if (validateStartTime && values.startTime) {
      const past3hr = unpackToTime(sessionStartTime).minus({ hours: 3 });
      const next3hr = unpackToTime(sessionStartTime).plus({ hours: 3 });
      if (DateTime.fromJSDate(values.startTime) > next3hr || DateTime.fromJSDate(values.startTime) < past3hr) {
        setStatus({ ...status, startTimeInvalidRange: true });
        return;
      }
      setStatus({ ...status, startTimeInvalidRange: false });
    }
  }, [validateStartTime, values.startTime]);

  useEffect(() => {
    if (validateEndTime && values.endTime) {
      const past3hr = unpackToTime(sessionEndTime).minus({ hours: 3 });
      const next3hr = unpackToTime(sessionEndTime).plus({ hours: 3 });
      if (DateTime.fromJSDate(values.endTime) > next3hr || DateTime.fromJSDate(values.endTime) < past3hr) {
        setStatus({ ...status, endTimeInvalidRange: true });
        return;
      }
      setStatus({ ...status, endTimeInvalidRange: false });
    }
  }, [validateEndTime, values.endTime]);

  return (
    <Grid container spacing={isMd ? 2 : 0} style={{ marginTop: '8px' }}>
      <Grid
        //
        item
        xs={12}
        md={6}
        style={{ paddingRight: 16 }}
      >
        <TimePicker
          required
          fullWidth
          inputVariant='outlined'
          name='startTime'
          label='Start Time'
          id='startTime'
          customError
        />
      </Grid>
      <Grid
        //
        item
        xs={12}
        md={6}
        style={{ paddingRight: 16 }}
      >
        <TimePicker
          required
          fullWidth
          inputVariant='outlined'
          name='endTime'
          label='End Time'
          id='endTime'
          customError
        />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 16 }}>
        {errors.endTime === 'incompatible' && (
          <AlertCard variant='urgent' title='Session times'>
            <Typography
              variant='subtitle2'
              display='inline'
              style={{ fontSize: '14px', fontWeight: 400, color: errorTextColor }}
            >
              The start and end times you have entered are incompatible - a report cannot end before it starts.
            </Typography>
          </AlertCard>
        )}
        {(errors.endTime || errors.startTime) === 'required' && (
          <AlertCard variant='urgent' title='Session times'>
            <Typography
              variant='subtitle2'
              display='inline'
              style={{ fontSize: '14px', fontWeight: 400, color: errorTextColor }}
            >
              Report requires a start and end time.
            </Typography>
          </AlertCard>
        )}
        {(errors.endTime || errors.startTime) === 'wrong-format' && (
          <AlertCard variant='urgent' title='Session times'>
            <Typography
              variant='subtitle2'
              display='inline'
              style={{ fontSize: '14px', fontWeight: 400, color: errorTextColor }}
            >
              Time format is HH:MM AM/PM
            </Typography>
          </AlertCard>
        )}
        <Box marginTop='0.5rem'>
          {status.startTimeInvalidRange && status.endTimeInvalidRange ? (
            <AlertCard variant='warning' title='Session times'>
              <Typography
                variant='subtitle2'
                display='inline'
                style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
              >
                The start and end time look a little different to the scheduled time for this session.
              </Typography>
            </AlertCard>
          ) : (
            (status.startTimeInvalidRange && (
              <AlertCard variant='warning' title='Start time'>
                <Typography
                  variant='subtitle2'
                  display='inline'
                  style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
                >
                  The start time looks a little different to the scheduled time for this session.
                </Typography>
              </AlertCard>
            )) ||
            (status.endTimeInvalidRange && (
              <AlertCard variant='warning' title='End time'>
                <Typography
                  variant='subtitle2'
                  display='inline'
                  style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
                >
                  The end time looks a little different to the scheduled time for this session
                </Typography>
              </AlertCard>
            ))
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const SessionAttendanceSection = (props: {
  publishedRoster?: ActivityTimelinePastPublishedRoster;
  session: ActivityTimelinePastReportSession;
}) => {
  const { publishedRoster, session } = props;
  const { theme } = useCampfireTheme();

  const [openAddVolunteerDialog, setOpenAddVolunteerDialog] = useState(false);
  const [openAddOtherAttendeeDialog, setOpenAddOtherAttendeeDialog] = useState(false);

  function handleCloseAddVolunteerDialog() {
    setOpenAddVolunteerDialog(false);
  }

  function handleCloseAddOtherAttendeeDialog() {
    setOpenAddOtherAttendeeDialog(false);
  }

  const formik = useFormikContext<SessionReportFormValuesType>();
  const { attendances } = formik.values;

  function addAttendances(volunteers: MultiSelectVolunteersDialogVolunteerType[]) {
    formik.setFieldValue('attendances', [
      ...attendances,
      ...volunteers.map((volunteer) => {
        return {
          type: 'volunteer',
          attendanceValue: true,
          ...volunteer,
        };
      }),
    ]);
  }

  function addOtherAttendee(attendeeName: string) {
    formik.setFieldValue('attendances', [
      ...attendances,
      {
        type: 'other',
        attendanceValue: true,
        name: attendeeName,
      },
    ]);
  }

  const ignoreVolunteerIds = useMemo(
    () =>
      attendances.reduce<Array<string>>(
        (prev, attendance) => (attendance.type === 'volunteer' ? [...prev, attendance.volunteerId] : prev),
        []
      ),
    [attendances]
  );

  return (
    <>
      <Box
        style={{
          borderBottom: `1px solid ${theme.color.grey.neutralBrand200}`,
          padding: '0px 8px 20px 0',
          color: theme.color.grey.neutral500,
          marginTop: 16,
        }}
      >
        <Typography style={{ fontWeight: 600 }}>Attendance</Typography>
        <Box style={{ boxSizing: 'border-box' }} pb={1}>
          {!publishedRoster && attendances.length === 0 ? (
            <>
              <Typography variant='body2'>{'No roster published'}</Typography>
              <Typography variant='body2' color='textSecondary'>
                {`A roster has not been published for this date. You can record attendance using the 'Add Volunteers' button.`}
              </Typography>
            </>
          ) : attendances.length === 0 ? (
            <>
              <Typography variant='body2'>{'Nobody was rostered for this session'}</Typography>
              <Typography variant='body2' color='textSecondary'>
                {`A roster has been published for this date, but no volunteers were chosen for this session. You can record attendance using the 'Add Volunteers' button.`}
              </Typography>
            </>
          ) : (
            <Box>
              <List>
                {attendances.map((attendance, attendanceIdx) => {
                  const volunteerAttendanceFormikFieldName = `attendances[${attendanceIdx}].attendanceValue`;
                  if (attendance.type === 'other') {
                    return (
                      <div key={attendance.name}>
                        <FormikField name={volunteerAttendanceFormikFieldName}>
                          {({ field: formikField, form: { setFieldValue } }: FormikFieldProps) => {
                            const isChecked = formikField.value;
                            return (
                              <ListItem
                                button
                                onClick={() => {
                                  setFieldValue(volunteerAttendanceFormikFieldName, !isChecked);
                                }}
                              >
                                <FormControlLabel
                                  {...formikField}
                                  control={<Checkbox checked={isChecked} color='primary' />}
                                  label={''}
                                />
                                <Avatar size={32} />
                                <Typography
                                  style={{ marginLeft: 8 }}
                                  color={isChecked ? 'textPrimary' : 'textSecondary'}
                                >
                                  {attendance.name}
                                </Typography>
                              </ListItem>
                            );
                          }}
                        </FormikField>
                      </div>
                    );
                  }
                  const { volunteerId, profile } = attendance;
                  const { preferredName, lastName, avatarUrl } = profile;
                  const volunteerName = `${preferredName} ${lastName}`;

                  return (
                    <div key={volunteerId}>
                      <FormikField name={volunteerAttendanceFormikFieldName}>
                        {({ field: formikField, form: { setFieldValue } }: FormikFieldProps) => {
                          const isChecked = formikField.value;
                          return (
                            <ListItem
                              button
                              onClick={() => {
                                setFieldValue(volunteerAttendanceFormikFieldName, !isChecked);
                              }}
                            >
                              <FormControlLabel
                                {...formikField}
                                control={<Checkbox checked={isChecked} color='primary' />}
                                label={''}
                              />
                              <Avatar
                                preferredName={preferredName}
                                lastName={lastName}
                                avatarUrl={avatarUrl}
                                size={35}
                              />
                              <Typography style={{ marginLeft: 8 }} color={isChecked ? 'textPrimary' : 'textSecondary'}>
                                {volunteerName}
                              </Typography>
                            </ListItem>
                          );
                        }}
                      </FormikField>
                    </div>
                  );
                })}
              </List>
            </Box>
          )}
          <Box display='flex' marginTop={2}>
            <Box
              display='flex'
              alignItems='center'
              ml={2}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setOpenAddVolunteerDialog(true)}
            >
              <AddCircle
                style={{
                  fontSize: '20px',
                  color: theme.color.secondary.main900,
                }}
              />
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: theme.color.secondary.main900,
                  marginLeft: '8px',
                }}
              >{`Add other volunteers`}</Typography>
            </Box>
          </Box>
        </Box>
        <HoverText
          data-track='actCnl-report-card-add-other-attendee'
          variant='caption'
          color='textSecondary'
          hoverColor='textSecondary'
          style={{
            marginLeft: '16px',
            marginTop: '4px',
            fontSize: '14px',
          }}
          onClick={() => {
            setOpenAddOtherAttendeeDialog(true);
          }}
        >
          Add Other Attendee
        </HoverText>
      </Box>

      <AddVolunteersDialog
        open={openAddVolunteerDialog}
        onClose={handleCloseAddVolunteerDialog}
        onSubmit={addAttendances}
        programId={session.activity.program.programId}
        ignoreVolunteerIds={ignoreVolunteerIds}
        dialogContentText={`Select one or more volunteers to add to the session report. Hit 'Cancel' to close this window and return to the report.`}
      />

      <AddOtherAttendeeDialog
        open={openAddOtherAttendeeDialog}
        onClose={handleCloseAddOtherAttendeeDialog}
        onSubmit={addOtherAttendee}
      />
    </>
  );
};

const SessionCICOSection = (props: {
  publishedRoster?: ActivityTimelinePastPublishedRoster;
  session: ActivityTimelinePastReportSession;
}) => {
  const { publishedRoster, session } = props;
  const { theme, isMobile } = useCampfireTheme();

  const [openAddVolunteerDialog, setOpenAddVolunteerDialog] = useState(false);

  function handleCloseAddVolunteerDialog() {
    setOpenAddVolunteerDialog(false);
  }

  const formik = useFormikContext<SessionReportFormValuesType>();
  const { CICOs } = formik.values;
  const volunteers = CICOs.map((item) => item.volunteer);

  function addCICO(vols: VolunteerType[]) {
    formik.setFieldValue('CICOs', [
      ...CICOs,
      ...vols.map((volunteer) => {
        return {
          checkIn: '',
          checkOut: '',
          isCICO: true,
          volunteer,
        };
      }),
    ]);
  }

  return (
    <>
      <Box
        style={{
          borderBottom: `1px solid ${theme.color.grey.neutralBrand200}`,
          padding: '0px 0px 20px 0',
          color: theme.color.grey.neutral500,
        }}
      >
        <Box display='flex' alignItems='center'>
          <Typography style={{ fontWeight: 600, minWidth: isMobile ? '130px' : '230px' }}>Attendance</Typography>
          <Typography style={{ fontWeight: 500, fontSize: '14px', minWidth: isMobile ? '82px' : '104px' }}>
            Start Time
          </Typography>
          <Typography style={{ fontWeight: 500, fontSize: '14px', minWidth: isMobile ? '80px' : '100px' }}>
            End Time
          </Typography>
          <Typography style={{ fontSize: '14px' }}>Total</Typography>
        </Box>
        <Box style={{ boxSizing: 'border-box' }} pb={1}>
          {!publishedRoster && CICOs.length === 0 ? (
            <>
              <Typography variant='body2'>{'No roster published'}</Typography>
              <Typography variant='body2' color='textSecondary'>
                {`A roster has not been published for this date. You can record attendance using the 'Add Volunteers' button.`}
              </Typography>
            </>
          ) : (
            <Box>
              <List>
                {CICOs.map((CICO, CICOIdx) => {
                  const isCICOFormikFieldName = `CICOs[${CICOIdx}].isCICO`;
                  const checkInFormikFieldName = `CICOs[${CICOIdx}].checkIn`;
                  const checkOutFormikFieldName = `CICOs[${CICOIdx}].checkOut`;
                  const { volunteer } = CICO;
                  if (!volunteer) return null;
                  const { preferredName, lastName, avatarUrl } = volunteer.profile;
                  const volunteerName = `${preferredName} ${lastName}`;

                  return (
                    <div key={volunteer.volunteerId}>
                      <FormikField name={isCICOFormikFieldName}>
                        {({ field: formikField, form: { setFieldValue } }: FormikFieldProps) => {
                          const isChecked = formikField.value;
                          return (
                            <Box display='flex' alignItems='center'>
                              <ListItem
                                button
                                onClick={() => {
                                  setFieldValue(isCICOFormikFieldName, !isChecked);
                                }}
                                style={{ padding: '8px' }}
                              >
                                <FormControlLabel
                                  {...formikField}
                                  control={
                                    <Checkbox
                                      checked={isChecked}
                                      style={{ padding: isMobile ? 0 : '4px' }}
                                      color='primary'
                                    />
                                  }
                                  label={''}
                                  style={{ marginRight: isMobile ? '2px' : '10px' }}
                                />
                                <Avatar
                                  preferredName={preferredName}
                                  lastName={lastName}
                                  avatarUrl={avatarUrl}
                                  size={isMobile ? 30 : 35}
                                />
                                <Typography
                                  style={{
                                    marginLeft: 8,
                                    fontSize: isMobile ? '14px' : '16px',
                                    maxWidth: isMobile ? '64px' : 'unset',
                                  }}
                                  color={isChecked ? 'textPrimary' : 'textSecondary'}
                                >
                                  {volunteerName}
                                </Typography>
                              </ListItem>
                              {isChecked && (
                                <>
                                  <Box mr={1}>
                                    <TextField
                                      onChange={(event) =>
                                        setFieldValue(
                                          checkInFormikFieldName,
                                          DateTime.fromFormat(event.target.value, 'hh:mm').toJSDate()
                                        )
                                      }
                                      value={CICO.checkIn ? DateTime.fromJSDate(CICO.checkIn).toFormat('HH:mm') : ''}
                                      variant='outlined'
                                      type='time'
                                      fullWidth
                                      name={checkInFormikFieldName}
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{
                                        step: 300, // 5 min
                                        style: { padding: isMobile ? '4px 2px' : '8px 12px', fontSize: '14px' },
                                      }}
                                      onBlur={() => {
                                        if (!CICO.checkIn) return;
                                        setFieldValue(checkInFormikFieldName, CICO.checkIn);
                                      }}
                                    />
                                  </Box>
                                  <Box mr={1}>
                                    <TextField
                                      onChange={(event) =>
                                        setFieldValue(
                                          checkOutFormikFieldName,
                                          DateTime.fromFormat(event.target.value, 'hh:mm').toJSDate()
                                        )
                                      }
                                      value={CICO.checkOut ? DateTime.fromJSDate(CICO.checkOut).toFormat('HH:mm') : ''}
                                      variant='outlined'
                                      type='time'
                                      fullWidth
                                      name={checkOutFormikFieldName}
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{
                                        step: 300, // 5 min
                                        style: { padding: isMobile ? '4px 2px' : '8px 12px', fontSize: '14px' },
                                      }}
                                      onBlur={() => {
                                        setFieldValue(checkOutFormikFieldName, CICO.checkOut);
                                      }}
                                    />
                                  </Box>
                                  <Box minWidth='80px' style={{ fontSize: '14px' }}>
                                    {`${
                                      CICO.checkIn && CICO.checkOut
                                        ? Math.abs(
                                            DateTime.fromJSDate(CICO.checkIn).diff(
                                              DateTime.fromJSDate(CICO.checkOut),
                                              'hours'
                                            ).hours
                                          ).toFixed(2)
                                        : '--'
                                    }${isMobile ? 'h' : ' hours'}`}
                                  </Box>
                                </>
                              )}
                            </Box>
                          );
                        }}
                      </FormikField>
                    </div>
                  );
                })}
              </List>
            </Box>
          )}
          <Box display='flex' marginTop={2}>
            <Box
              display='flex'
              alignItems='center'
              ml={'4px'}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setOpenAddVolunteerDialog(true)}
            >
              <AddCircle
                style={{
                  fontSize: '20px',
                  color: theme.color.secondary.main900,
                }}
              />
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: theme.color.secondary.main900,
                  marginLeft: '8px',
                }}
              >{`Add other volunteers`}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <AddOtherVolunteersDialog
        open={openAddVolunteerDialog}
        activity={session.activity}
        handleClose={handleCloseAddVolunteerDialog}
        handleAddOther={addCICO}
        currentVolunteers={volunteers}
      />
    </>
  );
};

const ThumbUpDownSection = (props: { name: string; label: string; required: boolean }) => {
  const { name, label, required } = props;
  return (
    <FormikField name={name}>
      {({ field: formikField }: FormikFieldProps) => {
        return (
          <RadioGroup
            aria-label={label}
            {...formikField}
            value={formikField.value || 'none'}
            style={{ display: 'inline-block' }}
          >
            <FormControlLabel
              value={'true'}
              label=''
              control={
                <Radio
                  required={required}
                  color='primary'
                  inputProps={{ 'aria-label': 'thumbs-up' }}
                  icon={
                    <ThumbUp
                      color={formikField.value !== undefined && formikField.value !== 'true' ? 'disabled' : 'action'}
                    />
                  }
                  checkedIcon={<ThumbUp color='primary' />}
                />
              }
            />

            <FormControlLabel
              value={'false'}
              label=''
              control={
                <Radio
                  required={required}
                  color='primary'
                  inputProps={{ 'aria-label': 'thumbs-down' }}
                  icon={
                    <ThumbDown
                      color={formikField.value !== undefined && formikField.value !== 'false' ? 'disabled' : 'action'}
                    />
                  }
                  checkedIcon={<ThumbDown color='primary' />}
                />
              }
            />
          </RadioGroup>
        );
      }}
    </FormikField>
  );
};

const RatingSection = React.memo((props: { name: string; label: string }) => {
  const { name, label } = props;
  const { errors, touched } = useFormikContext();
  const error = getIn(errors, name);
  const isTouched = getIn(touched, name);
  const ref: React.RefObject<HTMLDivElement> = React.createRef();

  useEffect(() => {
    if (error && isTouched) {
      scrollIntoView(ref, { behavior: 'smooth' }, true);
    }
  }, [error, isTouched]);

  return (
    <div ref={ref}>
      <Box component='fieldset' borderColor='transparent' padding={0} margin={0}>
        <Typography>{label}</Typography>
        <FormikField name={name}>
          {({ field: formikField }: FormikFieldProps) => {
            return <Rating size='large' {...formikField} value={Number.parseInt(formikField.value, 10)} />;
          }}
        </FormikField>
        {error && isTouched ? (
          <Typography color='error' variant='body2'>
            {error}
          </Typography>
        ) : null}
      </Box>
    </div>
  );
});

const AttachmentSection = React.memo((props: { name: string; label: string }) => {
  const { name, label } = props;
  const { errors, touched } = useFormikContext();
  const error = getIn(errors, name);
  const isTouched = getIn(touched, name);
  const ref: React.RefObject<HTMLDivElement> = React.createRef();

  useEffect(() => {
    if (error && isTouched) {
      scrollIntoView(ref, { behavior: 'smooth' }, true);
    }
  }, [error, isTouched]);

  const getAttachmentsBase64 = async (filesToParse: FugFile[]) => {
    return Promise.all(
      filesToParse.map(async (fugFile) => {
        const fileBase64 = await getBase64FromAttachment(fugFile);
        return fileBase64;
      })
    );
  };

  return (
    <div ref={ref}>
      <Box component='fieldset' borderColor='transparent' padding={0} margin={0}>
        <Typography component='legend'>{label}</Typography>
        <FormikField name={name}>
          {({ field: formikField, form }: FormikFieldProps) => {
            return (
              <FileUploadGallery
                allowMultiple
                uploadedFiles={formikField.value.attachments}
                handleFilesAdded={async (files) => {
                  form.setFieldValue(name, {
                    ...formikField.value,
                    addedAttachments: [...formikField.value.addedAttachments, ...files],
                    addedAttachmentsBase64: await getAttachmentsBase64([
                      ...formikField.value.addedAttachments,
                      ...files,
                    ]),
                  });
                }}
                handleFileRemoved={(file) => {
                  form.setFieldValue(name, {
                    ...formikField.value,
                    addedAttachments: formikField.value.addedAttachments.filter((existingFile: FugFile) => {
                      return existingFile.id !== file.id;
                    }),
                  });
                  form.setFieldValue(name, {
                    ...formikField.value,
                    attachments: formikField.value.attachments.filter((existingFile: UploadedFile) => {
                      return existingFile.fileId !== file.id;
                    }),
                  });
                  form.setFieldValue(name, {
                    ...formikField.value,
                    attachmentCreationTokens: formikField.value.attachmentCreationTokens.filter((token: string) => {
                      return token !== file.id;
                    }),
                  });
                  form.setFieldValue(name, {
                    ...formikField.value,
                    removedAttachmentIds: [...formikField.value.removedAttachmentIds, file.id],
                  });
                }}
              />
            );
          }}
        </FormikField>
        {error && isTouched ? (
          <Typography color='error' variant='body2'>
            {error}
          </Typography>
        ) : null}
      </Box>
    </div>
  );
});

const CheckboxSection = (props: { name: string; label: string; required: boolean }) => {
  const { name, label } = props;
  return (
    <FormikField name={name}>
      {({ field: formikField }: FormikFieldProps) => (
        <FormControlLabel
          {...formikField}
          control={<Checkbox color='primary' checked={formikField.value} />}
          label={label}
        />
      )}
    </FormikField>
  );
};

export { SessionReportFormV2 };
