import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Divider, IconButton, Menu, MenuItem, Switch, Typography } from '@material-ui/core';
import { Check, Clear, MoreHoriz } from '@material-ui/icons';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useBetween } from 'use-between';
import { Skeleton } from '@material-ui/lab';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { SearchField } from '../../../../common/inputs/SearchField';
import { SessionDetail } from '../sessions/SessionDetail';
import { ActivityHeaderDetail } from './activity-header-detail/ActivityHeaderDetail';
import { ActivitiesConsoleCardExploreActivity } from './__generated__/ActivitiesConsoleCardExploreActivity';
import { TeamInformationDetail, TeamMember } from '../team/TeamInformationDetail';
import { WaitlistedVolunteersDetail } from '../waitlisted-volunteers/WaitlistedVolunteersDetail';
import { NewVolunteersDetail } from '../new-volunteers/NewVolunteersDetail';
import {
  parseActivity,
  useApproveBulkIncomingVolunteersFetch,
  useSaveActivityFetch,
  useSaveActivityTeamFetch,
  useWaitlistBulkIncomingVolunteersFetch,
  useSetPriorityActivityFetch,
  useApproveIncomingVolunteerFetch,
  useWaitlistIncomingVolunteerFetch,
  useEnrolWaitlistingFetch,
  useUnenrolWaitlist,
} from '../activity-form-v2/activity-form-actions-v2';
import { AxiosResponseWithOk } from '../../../../global/network/useAxiosFetch';
import { useCampfireFetch } from '../../../../global/network/useCampfireFetch';
import { CampfireResponse } from '../../../../global/network/httpResponse';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { AttachmentDetail } from '../attachments/AttachmentDetail';
import { ActivityTagsDetail } from '../activity-tags/ActivityTagsDetail';
import { EditActivityTagsDialog } from '../activity-tags/EditActivityTagsDialog';
import { useApiUrl } from '../../../../global/config/useApiUrl';
import { GetActivityManagementActivityTags_vm_activityTags as ActivityTagType } from '../activity-form-v2/__generated__/GetActivityManagementActivityTags';
import {
  GetActivitiesConsoleTeamInformation,
  GetActivitiesConsoleTeamInformationVariables,
} from '../team/__generated__/GetActivitiesConsoleTeamInformation';
import { GET_ACTIVITIES_TEAM_INFORMATION } from '../team/team-information-model.gql';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { parsedTeamMember } from '../utils';
import { ConfirmAddMaxVolunteer } from '../../activities/activity-timeline/past/report/form/ConfirmAddMaxVolunteer';
import { GET_ACTIVITIES_NEW_VOLUNTEERS } from '../new-volunteers/new-volunteers-model.gql';
import {
  GetActivitiesConsoleNewVolunteers,
  GetActivitiesConsoleNewVolunteersVariables,
  GetActivitiesConsoleNewVolunteers_vm_activity_activeActivityApplications as ActivityApplicationType,
} from '../new-volunteers/__generated__/GetActivitiesConsoleNewVolunteers';
import { VolunteerCommonProfileProvider } from '../../volunteer-common-profile/VolunteerCommonProfileContext';
import { ActivityApplicationReviewDialog } from '../../activities/activity-timeline/management/ActivityApplicationReviewDialog';
import { useWithdrawApplication } from '../../activities/activity-timeline/activity-timeline-actions';
import {
  GetActivitiesConsoleWaitlistedVolunteers,
  GetActivitiesConsoleWaitlistedVolunteersVariables,
  GetActivitiesConsoleWaitlistedVolunteers_vm_activity_activeActivityWaitlistings as WailistType,
} from '../waitlisted-volunteers/__generated__/GetActivitiesConsoleWaitlistedVolunteers';
import { GET_ACTIVITIES_WAITLISTED_VOLUNTEERS } from '../waitlisted-volunteers/waitlisted-volunteers-model.gql';
import { WaitlistRemovingMessage } from '../../volunteers/waitlist-volunteers/WaitlistRemovingMessage';
import { ActivityWaitlistingReviewDialog } from '../../activities/activity-timeline/management/ActivityWaitlistingReviewDialog';

export interface SideBarHeaderProps {
  title: string;
  actionItems?: ReactNode;
  maxTeamCount?: number | null;
  numberOfEnrolments?: number | null;
}
interface SideBarItemProps {
  headerTitle: string;
  headerActionItems?: ReactNode;
  children: ReactNode;
  maxTeamCount?: number | null;
  numberOfEnrolments?: number | null;
}
interface Props {
  activityId?: string;
  activityInfo?: ActivitiesConsoleCardExploreActivity;
  activityTags: ActivityTagType[];
  getActivitiesCardDataRefetch?: () => Promise<{ data: any }>;
  getActivitiesCardDataLoading: boolean;
  getActivitiesCardDataRefetchLoading: boolean;
  refetchActivity: any;
}

export const SideBarHeader = (props: SideBarHeaderProps) => {
  const { theme } = useCampfireTheme();
  const { title, actionItems, maxTeamCount, numberOfEnrolments } = props;

  return (
    <Box
      display={'flex'}
      alignItems='center'
      justifyContent='space-between'
      borderBottom={`1px solid ${theme.color.grey.border}`}
    >
      <Box display={'flex'} alignItems='center'>
        <Typography
          variant='h6'
          noWrap
          style={{
            color: theme.color.grey.neutralBrand800,
            fontSize: '20px',
            fontWeight: 800,
            letterSpacing: '-0.02em',
          }}
        >
          {title}
        </Typography>

        {title === 'Team Information' && maxTeamCount && (
          <Typography
            variant='h6'
            noWrap
            style={{ color: theme.color.grey.neutral200, fontSize: '12px', marginLeft: '12px', marginTop: '3px' }}
          >
            {`${numberOfEnrolments}/${maxTeamCount} volunteers`}
          </Typography>
        )}
      </Box>
      {actionItems}
    </Box>
  );
};

const useSidebarState = () => {
  const [showMoreSessionInfo, setShowMoreSessionInfo] = useState(false);
  const [inTeamEditMode, setInTeamEditMode] = useState(false);
  const closeAll = () => {
    setInTeamEditMode(false);
    setShowMoreSessionInfo(false);
  };
  return {
    showMoreSessionInfo,
    setShowMoreSessionInfo,
    inTeamEditMode,
    setInTeamEditMode,
    closeAll,
  };
};

export const useSharedSidebarState = () => useBetween(useSidebarState);

export const SideBarItem = (props: SideBarItemProps) => {
  const { headerTitle, headerActionItems, maxTeamCount, numberOfEnrolments, children } = props;

  return (
    <Box margin='18px 0px'>
      <SideBarHeader
        title={headerTitle}
        actionItems={headerActionItems}
        maxTeamCount={maxTeamCount}
        numberOfEnrolments={numberOfEnrolments}
      />
      <Box py={1}>{children}</Box>
    </Box>
  );
};

export const SideBarActivityDetail = (props: Props) => {
  const {
    activityId,
    activityInfo,
    activityTags,
    getActivitiesCardDataLoading,
    getActivitiesCardDataRefetch,
    getActivitiesCardDataRefetchLoading,
    refetchActivity,
  } = props;
  const { theme, isMobile, isMd } = useCampfireTheme();
  const { showMoreSessionInfo, setShowMoreSessionInfo, inTeamEditMode, setInTeamEditMode } = useSharedSidebarState();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [applicationMembers, setApplicationMembers] = useState<ActivityApplicationType[]>([]);
  const [waitlists, setWaitlists] = useState<WailistType[]>([]);
  const [teamSearchTerm, setTeamSearchTerm] = useState('');
  const [selectedApplicationId, setSelectedApplicationId] = useState<string>();
  const { setSnackbar } = useSnackbar();
  const saveActivityTeam = useSaveActivityTeamFetch();
  const [waitlistSearchTerm, setWaitlistSearchTerm] = useState('');
  const [newVolunteersSearchTerm, setNewVolunteersSearchTerm] = useState('');
  const [openEditActivityTag, setOpenEditActivityTag] = useState(false);
  const saveActivity = useSaveActivityFetch();
  const setPriorityActivity = useSetPriorityActivityFetch();
  const campfireMutation = useCampfireFetch({ defer: true });
  const apiUrl = useApiUrl();
  const currentActivityTags =
    activityInfo?.activityTags.filter(
      (tag) =>
        tag &&
        activityInfo.activityTagged.some(
          (activityTaggedItem) => activityTaggedItem.activityTag.activityTagId === tag.activityTagId
        )
    ) || [];
  const currentActivityTagIds = currentActivityTags.map((tag) => tag.activityTagId);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const maxTeamCount = activityInfo?.maxTeam;

  const [isTeamExceed, setIsTeamExceed] = useState(false);
  const cancelConfirmMax = useRef<any>();
  const okConfirmMax = useRef<any>();

  const [enrolingWaitlistId, setEnrolingWatilistId] = useState<string>();
  const [removingWaitlistId, setRemovingWaitlistId] = useState<string>();

  const [getTeamInformation, { data: originalTeamInformation, loading: teamInfoLoading }] = useCampfireLazyQuery<
    GetActivitiesConsoleTeamInformation,
    GetActivitiesConsoleTeamInformationVariables
  >(GET_ACTIVITIES_TEAM_INFORMATION);

  const [getApplicationInformation, { loading: applicationLoading }] = useCampfireLazyQuery<
    GetActivitiesConsoleNewVolunteers,
    GetActivitiesConsoleNewVolunteersVariables
  >(GET_ACTIVITIES_NEW_VOLUNTEERS);

  const [getActivityWaitlists, { loading: waitlistLoading }] = useCampfireLazyQuery<
    GetActivitiesConsoleWaitlistedVolunteers,
    GetActivitiesConsoleWaitlistedVolunteersVariables
  >(GET_ACTIVITIES_WAITLISTED_VOLUNTEERS);

  const approveApplication = useApproveIncomingVolunteerFetch();
  const withdrawApplication = useWithdrawApplication();
  const waitlistIncomingVolunteer = useWaitlistIncomingVolunteerFetch();

  const enrolWaitlisting = useEnrolWaitlistingFetch();
  const unenrolWaitlisting = useUnenrolWaitlist();
  const approveApplicationBulk = useApproveBulkIncomingVolunteersFetch();
  const waitlistBulkIncomingVolunteers = useWaitlistBulkIncomingVolunteersFetch();

  const getAndParsedTeam = useCallback(() => {
    if (activityId) {
      getTeamInformation({ variables: { activityId } }).then((response) =>
        setTeamMembers(parsedTeamMember(response.data?.vm.activity?.activityEnrolments))
      );
    }
  }, [activityId]);

  const getAndParsedApplication = useCallback(() => {
    if (activityId) {
      getApplicationInformation({ variables: { activityId } }).then((response) =>
        setApplicationMembers(response.data?.vm.activity?.activeActivityApplications ?? [])
      );
    }
  }, [activityId]);

  const getAndParsedWaitlists = useCallback(() => {
    if (activityId) {
      getActivityWaitlists({ variables: { activityId } }).then((response) =>
        setWaitlists(response.data?.vm.activity?.activeActivityWaitlistings ?? [])
      );
    }
  }, [activityId]);

  useEffect(() => {
    if (activityId) {
      getAndParsedTeam();
      getAndParsedApplication();
      getAndParsedWaitlists();
    }
  }, [activityId]);

  const onAdd = (applicationId: string) => {
    const teamMembersCount = originalTeamInformation?.vm.activity?.activityEnrolments.length;
    if (maxTeamCount && teamMembersCount && maxTeamCount <= teamMembersCount) {
      setIsTeamExceed(true);
      okConfirmMax.current = () => {
        setSelectedApplicationId(applicationId);
        setIsTeamExceed(false);
      };

      cancelConfirmMax.current = () => {
        setIsTeamExceed(false);
      };
    } else {
      setSelectedApplicationId(applicationId);
    }
  };

  const onWaitlist = () => {
    if (selectedApplicationId) {
      waitlistIncomingVolunteer
        .run({
          activityApplicationId: selectedApplicationId,
        })
        .then((response) => {
          if (response.ok) {
            setSnackbar({
              open: true,
              message: 'Move application to waitlist',
              variant: 'success',
            });
            getAndParsedApplication();
            getAndParsedTeam();
            getAndParsedWaitlists();
            setSelectedApplicationId(undefined);
            if (refetchActivity) {
              refetchActivity();
            }
          }
        });
    }
  };

  const onApply = () => {
    if (selectedApplicationId) {
      approveApplication
        .run({
          activityApplicationId: selectedApplicationId,
        })
        .then((response) => {
          setSelectedApplicationId(undefined);
          if (response.ok) {
            setSnackbar({
              open: true,
              message: 'Add volunteer to team',
              variant: 'success',
            });
            getAndParsedApplication();
            getAndParsedTeam();
            if (refetchActivity) {
              refetchActivity();
            }
          }
        });
    }
  };

  const onRemoveApplication = (applicationId: string) => {
    if (activityId) {
      withdrawApplication.run({ activityApplicationId: applicationId, activityId }).then((response) => {
        setSelectedApplicationId(undefined);
        if (response.ok) {
          getAndParsedApplication();
          getAndParsedTeam();
          setSnackbar({
            open: true,
            message: 'Remove application',
            variant: 'success',
          });
          if (refetchActivity) {
            refetchActivity();
          }
        }
      });
    }
  };

  const onAddWaitlist = (waitlistId: string) => {
    const teamCount = originalTeamInformation?.vm.activity?.activityEnrolments.length;
    if (maxTeamCount && teamCount && maxTeamCount <= teamCount) {
      setIsTeamExceed(true);
      cancelConfirmMax.current = () => {
        setIsTeamExceed(false);
      };
      okConfirmMax.current = () => {
        setEnrolingWatilistId(waitlistId);
        setIsTeamExceed(false);
      };
    } else {
      setEnrolingWatilistId(waitlistId);
    }
  };

  const onRemoveWaitlist = (waitlistId: string) => {
    setRemovingWaitlistId(waitlistId);
  };

  const editTeam = () => {
    if (inTeamEditMode) {
      setTeamMembers(parsedTeamMember(originalTeamInformation?.vm.activity?.activityEnrolments));
    }
    setInTeamEditMode(!inTeamEditMode);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!activityId) {
      setInTeamEditMode(false);
      setShowMoreSessionInfo(false);
    }
  });

  const handleActionPromise = <Res extends unknown = unknown>({
    promise,
    successMessage,
    errorMessage,
    refetchOnSuccess,
    onSuccess,
  }: {
    promise: Promise<AxiosResponseWithOk<CampfireResponse<Res>>>;
    successMessage: string;
    errorMessage: string;
    onSuccess?: (res: Res) => void;
    refetchOnSuccess?: boolean;
  }) => {
    promise
      .then((res) => {
        if (res.ok) {
          if (onSuccess) onSuccess(res.data.data);
          if (refetchOnSuccess && refetchActivity) refetchActivity();
          setSnackbar({
            open: true,
            message: successMessage,
            variant: 'success',
          });

          return;
        }

        setSnackbar({
          open: true,
          message: errorMessage,
          variant: 'error',
        });
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: errorMessage,
          variant: 'error',
        })
      );
  };

  const runSaveActivityTeam = () => {
    if (activityId) {
      handleActionPromise({
        promise: saveActivityTeam.run({
          activityId,
          teamMembers,
        }),
        successMessage: 'Team saved',
        errorMessage: 'Unable to save team',
        onSuccess: () => {
          setInTeamEditMode(false);
          getAndParsedTeam();
          if (refetchActivity) {
            refetchActivity();
          }
        },
      });
    }
  };

  const newVolunteerBulkWaitlist = () => {
    waitlistBulkIncomingVolunteers
      .run({
        activityApplicationIds: applicationMembers.map((aM) => aM.activityApplicationId),
      })
      .then((response) => {
        if (response.ok) {
          setSnackbar({
            open: true,
            message: 'Move all applications to waitlist',
            variant: 'success',
          });
          getAndParsedWaitlists();
          getAndParsedApplication();
        }
      });
    handleClose();
  };

  const newVolunteerBulkApprove = () => {
    approveApplicationBulk
      .run({
        activityApplicationIds: applicationMembers.map((aM) => aM.activityApplicationId),
      })
      .then((response) => {
        if (response.ok) {
          setSnackbar({
            open: true,
            message: 'Apply all applications',
            variant: 'success',
          });
          getAndParsedTeam();
          getAndParsedApplication();
          if (refetchActivity) {
            refetchActivity();
          }
        }
      });
    handleClose();
  };

  const requestBulkApprove = () => {
    if (maxTeamCount && applicationMembers.length + teamMembers.length > maxTeamCount) {
      setIsTeamExceed(true);
      cancelConfirmMax.current = () => {
        setIsTeamExceed(false);
        handleClose();
      };
      okConfirmMax.current = () => {
        setIsTeamExceed(false);
        newVolunteerBulkApprove();
      };
    } else {
      newVolunteerBulkApprove();
    }
  };

  const runUpdateEnrollable = (value: boolean) => {
    if (!activityInfo || Boolean(activityInfo.maxTeam)) return;
    handleActionPromise({
      promise: saveActivity.run(
        parseActivity({
          ...activityInfo,
          isEnrollable: !value,
        })
      ),
      successMessage: 'Activity waitlist status updated',
      errorMessage: 'Unable to update activity waitlist status',
      refetchOnSuccess: true,
    });
  };

  const runUpdatePriorioty = (priority: boolean) => () => {
    if (!activityInfo) return;
    setPriorityActivity
      .run({
        activityId: activityInfo.activityId,
        programId: activityInfo.program.programId,
        priority: priority,
      })
      .then((res) => {
        if (!res.ok) {
          const error = res.data.errors?.error || '';
          setSnackbar({
            open: true,
            message:
              error === 'Priority maximum reached'
                ? 'Priority maximum reached'
                : 'Unable to update priority activity status',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Priority activity status updated',
          variant: 'success',
        });
        if (getActivitiesCardDataRefetch) getActivitiesCardDataRefetch();
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: 'Unable to update priority activity status',
          variant: 'error',
        });
      });
  };

  const onEditActivityTagsClick = () => {
    setOpenEditActivityTag(true);
  };

  const handleCloseEditActivityTags = () => {
    setOpenEditActivityTag(false);
  };

  const removingWaitlist = useMemo(() => {
    if (!removingWaitlistId) {
      return null;
    }
    return waitlists.find((w) => w.activityWaitlistingId === removingWaitlistId);
  }, [removingWaitlistId, waitlists]);

  const onEnrolWaitlist = () => {
    if (enrolingWaitlistId) {
      enrolWaitlisting
        .run({
          activityWaitlistingId: enrolingWaitlistId,
        })
        .then((response) => {
          if (response.ok) {
            setSnackbar({
              open: true,
              message: 'Enrol volunteer to team',
              variant: 'success',
            });
            getAndParsedWaitlists();
            getAndParsedTeam();
            if (refetchActivity) {
              refetchActivity();
            }
          }
        });
    }
  };

  const onWidthrawWaitlist = (message: string) => {
    if (removingWaitlistId) {
      unenrolWaitlisting
        .run({
          activityWaitlistingId: removingWaitlistId,
          removalReason: message,
        })
        .then((response) => {
          if (response.ok) {
            setSnackbar({
              open: true,
              message: 'Remove waitlist',
              variant: 'success',
            });
            getAndParsedWaitlists();
          }
        });
    }
  };

  const handleSaveActivityTags = (selectedActivityTagIds: string[]) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity/update-activity-tag`,
        method: 'post',
        data: {
          activityId,
          activityTagIds: selectedActivityTagIds,
        },
      })
      .then(() => {
        setSnackbar({
          open: true,
          message: 'Activity Tags updated',
          variant: 'success',
        });
        if (getActivitiesCardDataRefetch) getActivitiesCardDataRefetch();
      });
  };

  if (getActivitiesCardDataLoading || getActivitiesCardDataRefetchLoading || !activityInfo) {
    return (
      <Box width='100%' display={isMobile || isMd ? 'auto' : 'flex'} flexDirection='column'>
        <SideBarSkeleton />
      </Box>
    );
  }

  console.log(activityInfo.isEnrollable, 'xxx');

  return (
    <Box width='100%' display={isMobile || isMd ? 'auto' : 'flex'} flexDirection='column'>
      <>
        <ActivityHeaderDetail
          activityInfo={activityInfo}
          getActivitiesCardDataLoading={getActivitiesCardDataLoading}
          getActivitiesCardDataRefetch={getActivitiesCardDataRefetch}
        />
        <SideBarItem
          headerTitle='Sessions'
          headerActionItems={
            <HoverText
              color='primary'
              variant='body2'
              onClick={() => setShowMoreSessionInfo(!showMoreSessionInfo)}
              style={{ fontSize: '12px' }}
            >
              {showMoreSessionInfo ? 'Hide Details' : 'Show Details'}
            </HoverText>
          }
        >
          <SessionDetail sessions={activityInfo.sessions} showMoreSessionInfo={showMoreSessionInfo} />
        </SideBarItem>
        <SideBarItem
          headerTitle='Team Information'
          maxTeamCount={maxTeamCount}
          numberOfEnrolments={teamMembers.length}
          headerActionItems={
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              {isMd && inTeamEditMode ? (
                <TabletButton
                  variant='outlined'
                  size='small'
                  onClick={() => editTeam()}
                  style={{
                    color: '#ffffff',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    borderColor: theme.palette.error.main,
                    textTransform: 'none',
                    padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
                    margin: '0px 4px',
                    minWidth: '40px',
                  }}
                >
                  <Clear
                    color='error'
                    style={{
                      fontSize: '16px',
                    }}
                  />
                </TabletButton>
              ) : (
                <HoverText
                  color={inTeamEditMode ? 'error' : 'primary'}
                  variant='body2'
                  onClick={() => editTeam()}
                  style={{ display: 'inline-block', fontSize: '12px', margin: '0 4px' }}
                >
                  {!inTeamEditMode ? 'Edit' : 'Cancel'}
                </HoverText>
              )}
              {inTeamEditMode ? (
                <TabletButton
                  data-track='actCnl-overview-done-editing-team'
                  variant='contained'
                  size='small'
                  onClick={() => {
                    runSaveActivityTeam();
                  }}
                  style={{
                    backgroundColor: theme.color.secondary.main900,
                    color: '#ffffff',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    textTransform: 'none',
                    padding: !isMobile || !isMd ? '7px 12px' : '7px 7px',
                    margin: '0px 4px',
                    minWidth: '40px',
                  }}
                >
                  {isMd ? (
                    <Check
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  ) : (
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      Done
                    </Typography>
                  )}
                </TabletButton>
              ) : null}
              <SearchField
                data-track='actCnlFilter-SearchInput'
                placeholder=''
                onChange={(e) => setTeamSearchTerm(e.target.value)}
              />
            </Box>
          }
        >
          <TeamInformationDetail
            activityId={activityId}
            programId={activityInfo.program.programId}
            inEditMode={inTeamEditMode}
            searchTerm={teamSearchTerm}
            teamMembers={teamMembers}
            setTeamMembers={setTeamMembers}
            maxTeam={activityInfo.maxTeam ?? undefined}
            isLoading={teamInfoLoading}
          />
        </SideBarItem>
        <SideBarItem
          headerTitle='Applications'
          headerActionItems={
            applicationMembers.length > 1 ? (
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Box>
                  <Box
                    style={{
                      border: '1px solid #9e9e9e',
                      boxSizing: 'border-box',
                      borderRadius: '4px',
                    }}
                  >
                    <IconButton
                      aria-label='more'
                      id='long-button'
                      aria-controls='long-menu'
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup='true'
                      onClick={handleClick}
                      style={{
                        color: theme.color.grey.neutral300,
                        padding: '0px 2px',
                        fontSize: '26px',
                        borderRadius: 'none',
                      }}
                    >
                      <MoreHoriz fontSize={'inherit'} style={{ borderRadius: 'none' }} />
                    </IconButton>
                  </Box>
                  <Menu
                    id='long-menu'
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        newVolunteerBulkWaitlist();
                      }}
                    >
                      {'Move all to Waitlist'}
                    </MenuItem>
                    <MenuItem onClick={requestBulkApprove}>{'Approve All'}</MenuItem>
                  </Menu>
                </Box>
                <Box paddingLeft={'5px'}>
                  <SearchField
                    data-track='actCnlFilter-SearchInput'
                    placeholder=''
                    onChange={(e) => setNewVolunteersSearchTerm(e.target.value)}
                  />
                </Box>
              </Box>
            ) : null
          }
        >
          <NewVolunteersDetail
            activityId={activityId}
            applications={applicationMembers}
            onRemove={onRemoveApplication}
            onAdd={onAdd}
            programId={activityInfo.program.programId}
            searchTerm={newVolunteersSearchTerm}
            isLoading={applicationLoading}
          />
        </SideBarItem>
        <SideBarItem
          headerTitle='Waitlist'
          headerActionItems={
            <SearchField
              data-track='actCnlFilter-SearchInput'
              placeholder=''
              onChange={(e) => setWaitlistSearchTerm(e.target.value)}
            />
          }
        >
          <WaitlistedVolunteersDetail
            activityId={activityId}
            programId={activityInfo.program.programId}
            searchTerm={waitlistSearchTerm}
            waitlists={waitlists}
            onAdd={onAddWaitlist}
            onRemove={onRemoveWaitlist}
            isLoading={waitlistLoading}
          />
        </SideBarItem>
        <SideBarItem headerTitle='Attachments'>
          <AttachmentDetail activityId={activityId} />
        </SideBarItem>
        <SideBarItem
          headerTitle='Prioritise this Activity'
          headerActionItems={
            <Switch
              checked={activityInfo.priority}
              onChange={runUpdatePriorioty(!activityInfo.priority)}
              color='primary'
              name='pinned'
              inputProps={{ 'aria-label': 'pinned checkbox' }}
            />
          }
        >
          <Typography variant='body2' color='textSecondary'>
            {`Prioritising an activity means that volunteers will see this before others. Volunteers are more likely to join a prioritised activity.`}
          </Typography>
        </SideBarItem>
        <SideBarItem
          headerTitle='Volunteers Waitlist'
          headerActionItems={
            <Switch
              checked={!activityInfo.isEnrollable}
              onChange={(_unusedEvent, value) => runUpdateEnrollable(value)}
              color='primary'
              name='pinned'
              inputProps={{ 'aria-label': 'pinned checkbox' }}
            />
          }
        >
          <Typography
            style={{
              fontWeight: 600,
              color: theme.color.grey.neutralBrand800,
              fontSize: '16px',
            }}
          >
            {'Default new volunteers to waitlist'}
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            {`If your activity doesn't have the capacity for more volunteer intake, turn on the waitlist so
                        that volunteers know that they might not be able to join this activity immediately but are still
                        able to express their interest.`}
          </Typography>
        </SideBarItem>
        <SideBarItem
          headerTitle='Activity Tags'
          headerActionItems={
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <HoverText
                color={'primary'}
                variant='body2'
                onClick={() => onEditActivityTagsClick()}
                style={{ display: 'inline-block', fontSize: '12px', margin: '0 4px' }}
              >
                {'Edit'}
              </HoverText>
            </Box>
          }
        >
          <ActivityTagsDetail currentActivityTags={currentActivityTags} />
          {currentActivityTagIds && openEditActivityTag && (
            <EditActivityTagsDialog
              open={openEditActivityTag}
              handleClose={handleCloseEditActivityTags}
              activityTags={activityTags}
              currentActivityTagIds={currentActivityTagIds}
              handleSaveActivityTags={handleSaveActivityTags}
            />
          )}
        </SideBarItem>
      </>
      {isTeamExceed && <ConfirmAddMaxVolunteer onCancel={cancelConfirmMax.current} onOK={okConfirmMax.current} />}
      <VolunteerCommonProfileProvider>
        {selectedApplicationId && (
          <ActivityApplicationReviewDialog
            handleClose={() => setSelectedApplicationId(undefined)}
            handleApprove={onApply}
            handleWaitlist={onWaitlist}
            activityApplicationId={selectedApplicationId}
          />
        )}
      </VolunteerCommonProfileProvider>
      {enrolingWaitlistId && (
        <ActivityWaitlistingReviewDialog
          handleClose={() => setEnrolingWatilistId(undefined)}
          handleEnrol={() => {
            onEnrolWaitlist();
            setEnrolingWatilistId(undefined);
          }}
          activityWaitlistingId={enrolingWaitlistId}
        />
      )}
      <WaitlistRemovingMessage
        open={Boolean(removingWaitlist)}
        onClose={() => {
          setRemovingWaitlistId(undefined);
        }}
        onRemove={(message) => onWidthrawWaitlist(message)}
        title={`Remove: ${removingWaitlist?.volunteer.profile.preferredName} ${removingWaitlist?.volunteer.profile?.lastName}`}
        bottomText={`The reason for removal will be sent to the volunteer after clicking the ’Remove’ button.`}
        warningText={`You are removing this volunteer's application`}
      />
    </Box>
  );
};

const SideBarSkeleton = () => (
  <>
    <Skeleton variant={'text'} width={'20%'} height={'30px'} />
    <Divider />
    <Skeleton variant={'text'} width={'100%'} height={'36px'} />
    <Skeleton variant={'text'} width={'100%'} height={'36px'} />
  </>
);
