import React, { useMemo, useState } from 'react';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Button, Grid, IconButton, ListItem, Tooltip, Typography } from '@material-ui/core';
import { AddCircle, Clear, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { VolunteerProfile } from '../../../../common/AvatarName';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { AddVolunteersDialog } from '../../activities/activity-timeline/past/report/form/AddVolunteersDialog';
import { TeamInformationSkeleton } from './TeamInformationSkeleton';

interface Props {
  activityId?: string;
  programId: string;
  inEditMode: boolean;
  searchTerm: string;
  teamMembers: TeamMember[];
  setTeamMembers: React.Dispatch<React.SetStateAction<TeamMember[]>>;
  maxTeam?: number;
  isLoading: boolean;
}

interface TeamMemberItemProps {
  teamMember?: any;
  inEditMode: boolean;
}

export type TeamMember = {
  profileId: string;
  userId: string;
  volunteerId: string;
  preferredName: string;
  lastName: string;
  avatarUrl?: string;
  position: string;
};

const TeamMemberItem = (props: TeamMemberItemProps) => {
  const { theme, isMobile, isMd } = useCampfireTheme();
  const { teamMember, inEditMode } = props;
  const displayName = teamMember
    ? teamMember.preferredName
      ? `${teamMember.preferredName} ${teamMember.lastName}`
      : `${teamMember.firstName} ${teamMember.lastName}`
    : '';
  return (
    <Grid
      container
      wrap={'nowrap'}
      spacing={1}
      style={{
        marginTop: 8,
        marginBottom: 8,
      }}
      key={teamMember.volunteerId}
    >
      <Grid
        item
        style={{
          minWidth: isMobile ? 180 : isMd && !inEditMode ? 215 : isMd && inEditMode ? 180 : 270,
          width:
            isMobile && teamMember.position !== 'al'
              ? 215
              : isMobile && teamMember.position === 'al'
              ? 180
              : isMd
              ? 215
              : 270,
        }}
      >
        <VolunteerProfile
          avatarUrl={teamMember.avatarUrl}
          name={displayName}
          noTrim
          profileId={teamMember.profileId}
          userId={teamMember.userId}
          lastName={teamMember.lastName}
          preferredName={teamMember.preferredName}
        />
      </Grid>
      {teamMember.position === 'al' ? (
        <Grid item container justify='flex-end'>
          <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='flex-end'>
            <Grid item>
              <Typography
                style={{
                  textTransform: 'uppercase',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: theme.color.grey.neutral200,
                }}
              >
                {'Activity Leader'}
              </Typography>
            </Grid>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export const TeamInformationDetail = (props: Props) => {
  const { theme } = useCampfireTheme();
  const { inEditMode, programId, searchTerm, teamMembers, maxTeam, setTeamMembers, isLoading } = props;
  const [showMore, setShowMore] = useState<boolean>(false);
  const [showAddVolunteerToTeamDialog, setShowAddVolunteerToTeamDialog] = useState(false);

  const filteredTeamMembers = useMemo(() => {
    return teamMembers.filter((teamMember) => {
      const fullName = `${teamMember.preferredName} ${teamMember.lastName}`;
      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [searchTerm, teamMembers]);

  const displayingTeamMembers = useMemo(() => {
    if (showMore) {
      return filteredTeamMembers;
    }

    return filteredTeamMembers.slice(0, 5);
  }, [showMore, filteredTeamMembers]);

  const maxAddVolunteerSelected = useMemo(() => {
    if (!maxTeam) return null;
    return maxTeam - teamMembers.length;
  }, [maxTeam, teamMembers]);

  return (
    <Box>
      <AddVolunteersDialog
        open={showAddVolunteerToTeamDialog}
        onClose={() => setShowAddVolunteerToTeamDialog(false)}
        onSubmit={(volunteers) => {
          setTeamMembers(
            teamMembers.concat(
              volunteers.map((volunteer) => ({
                profileId: volunteer.profile.profileId,
                userId: volunteer.profile.userId,
                volunteerId: volunteer.volunteerId,
                preferredName: volunteer.profile.preferredName,
                lastName: volunteer.profile.lastName,
                avatarUrl: volunteer.profile.avatarUrl ?? undefined,
                position: 'gv',
              }))
            )
          );
        }}
        programId={programId}
        maxSelected={maxAddVolunteerSelected}
        ignoreVolunteerIds={teamMembers.map((x) => x.volunteerId)}
        dialogContentText={`Select one or more volunteers to add to the team. Hit 'Cancel' to close this window.`}
      />
      {inEditMode && teamMembers.length ? (
        <Box maxWidth={480} my={1}>
          <Typography
            variant='caption'
            style={{ whiteSpace: 'pre-line' }}
          >{`Click on a volunteer's name to make them an Activity Leader.`}</Typography>
        </Box>
      ) : null}
      {isLoading
        ? Array.from(Array(5)).map(() => (
            <ListItem>
              <TeamInformationSkeleton />
            </ListItem>
          ))
        : displayingTeamMembers.map((ae) => (
            <Box display={inEditMode ? 'flex' : ''} alignItems={'center'} key={ae.volunteerId}>
              <Box>
                {inEditMode ? (
                  <Tooltip title='Remove from activity' arrow placement='bottom'>
                    <IconButton
                      style={{ display: 'inline-block' }}
                      onClick={() => {
                        setTeamMembers(teamMembers.filter((x) => x.volunteerId !== ae.volunteerId));
                      }}
                    >
                      <Clear color='error' />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Box>
              <Box
                onClick={() => {
                  const newPosition = ae.position === 'gv' ? 'al' : 'gv';
                  setTeamMembers(
                    teamMembers.map((x) => {
                      return {
                        ...x,
                        position: x.volunteerId === ae.volunteerId ? newPosition : x.position,
                      };
                    })
                  );
                }}
                style={{
                  width: '-webkit-fill-available',
                }}
              >
                <TeamMemberItem teamMember={ae} inEditMode={inEditMode} />
              </Box>
            </Box>
          ))}
      {inEditMode ? (
        <Box paddingTop='15px'>
          <TabletButton
            data-track='actCnl-overview-done-editing-team'
            variant='contained'
            onClick={() => {
              setShowAddVolunteerToTeamDialog(true);
            }}
            startIcon={<AddCircle style={{ color: theme.color.secondary.main700 }} />}
          >
            {'Add Team Member/s'}
          </TabletButton>
        </Box>
      ) : (
        ''
      )}
      <Box style={{ width: '100%', paddingTop: '12px' }}>
        {filteredTeamMembers.length > 5 ? (
          <Button
            variant='text'
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: '8px 12px',
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '8px',
              display: 'block',
            }}
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? (
              <Box display='flex'>
                <Typography
                  style={{
                    color: theme.color.grey.neutral400,
                    fontSize: '14px',
                    textTransform: 'none',
                    marginRight: '4px',
                  }}
                >
                  Show Less
                </Typography>
                <KeyboardArrowUp style={{ fontSize: '20px', color: theme.color.grey.neutral400 }} />
              </Box>
            ) : (
              <Box display='flex'>
                <Typography
                  style={{
                    color: theme.color.grey.neutral400,
                    fontSize: '14px',
                    textTransform: 'none',
                    marginRight: '4px',
                  }}
                >
                  Show More
                </Typography>
                <KeyboardArrowDown style={{ fontSize: '20px', color: theme.color.grey.neutral400 }} />
              </Box>
            )}
          </Button>
        ) : (
          ''
        )}
        {!teamMembers.length && !isLoading && !inEditMode ? (
          <Box textAlign='center' fontSize='13px' paddingTop='15px' color={theme.color.grey.neutral400}>
            <Typography style={{ fontSize: '13px' }}>There are no volunteers assigned to this activity</Typography>
            <Typography style={{ paddingTop: '5px', fontSize: '13px' }}>
              Click &#39;Edit&#39; to add team members
            </Typography>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};
