import { gql } from '@apollo/client';
import { RECURRENCE_FRAGMENT } from '../../../../common/recurrence/recurrence-model.gql';

export const TASK_DASHBOARD = gql`
  query GetTaskDashboard($status: String) {
    vm {
      tasks(status: $status) {
        taskId
        title
        description
        automateApproval
        hasRules
        allowUpdate
        isHidden
        applicantsOnly
        recurrences {
          ...Recurrence
        }
        expiryDate
        programs {
          programId
        }
        roles {
          activityRoleId
        }
        metadata {
          totalPendingApprovals
        }
      }
    }
  }
  ${RECURRENCE_FRAGMENT}
`;
