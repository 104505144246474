import React, { useEffect, useMemo, useState } from 'react';
import { PaneWrapper } from '@campfire/pane';
import { Route, withRouter } from 'react-router';
import { Box, Dialog } from '@material-ui/core';
import { StringParam, BooleanParam, useQueryParam } from 'use-query-params';
import { DateTime } from 'luxon';
import { uniqBy } from 'lodash';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { CollapsibleSideBar } from '../../../common/CollapsibleSideBar';
import { CommonScreenSkeleton } from '../../../common/CommonScreenSkeleton';
import { ActivitiesList } from './activities-list/ActivitiesList';
import { SideBarActivityDetail } from './activities-list/SideBarActivityDetail';
import { useUser } from '../../../global/auth/useUser';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import {
  GetActivitiesConsoleCardData,
  GetActivitiesConsoleCardDataVariables,
} from './activities-list/__generated__/GetActivitiesConsoleCardData';
import {
  GET_ACTIVITIES_CARD_DATA,
  GET_ACTIVITIES_CONSOLE_ACTIVITIES,
  GET_ACTIVITY_CARD_DATA,
} from './activities-list/activities-model.gql';
import {
  GetActivitiesConsoleActivities,
  GetActivitiesConsoleActivitiesVariables,
} from './activities-list/__generated__/GetActivitiesConsoleActivities';
import { ActivitiesConsoleCardExploreActivity } from './activities-list/__generated__/ActivitiesConsoleCardExploreActivity';
import { CampfireSwitch } from '../../../content-blocks/common/CampfireSwitch';
import { EditActivityScreenV2 } from './activity-form-v2/EditActivityScreenV2';
import { DuplicateActivityScreenV2 } from './activity-form-v2/DuplicateActivityScreenV2';
import { CreateActivityScreenV2 } from './activity-form-v2/CreateActivityScreenV2';
import { ActivitiesManagementNavItemsWrapper, MANAGER_SORT_FILTERS } from './ActivityManagementNavItems';
import { useCampfireLazyQuery } from '../../../global/network/useCampfireLazyQuery';
import { ActivityManagementContextProvider } from './context/ActivityContext';
import { ProgramsContext } from '../../../global/programs-sell/ProgramsContext';
import { ProgramFiltersWrapper } from '../../../global/program-filters/ProgramFiltersWrapper';
import { useDeepEffect } from '../../../hooks/useDeepEffect';
import { GetActivityManagementActivityTags } from './activity-form-v2/__generated__/GetActivityManagementActivityTags';
import { GET_ACTIVITY_MANAGEMENT_ACTIVITY_TAGS } from './activity-form-v2/activity-form-model-v2.gql';
import { buildRowCsv, buildCsvData } from './common-v2/activities-export-csv';
import { downloadCSV } from '../../../common/functions/csv-utils';
import { Page } from '../../../global/components';
import { ActivityManagementTutorialDialog } from './ActivityManagementTutorialDialog';
import { ActivitiesMap } from '../../general/activities-explore/ActivitiesMap';
import {
  GetActivityConsoleCardData,
  GetActivityConsoleCardDataVariables,
  GetActivityConsoleCardData_vm_activity as IActivity,
} from './activities-list/__generated__/GetActivityConsoleCardData';

const ActivityManagementActivities = () => {
  const { isMd, isMobile } = useCampfireTheme();
  const [selectedActivityId, setSelectedActivityId] = useQueryParam('activityId', StringParam);
  const [hoveredActivityId, setHoveredActivityId] = useState<string | undefined>();
  const [showMap] = useQueryParam('map', BooleanParam);
  const [selectedProgramId, setSelectedProgramId] = useQueryParam('programId', StringParam);
  const [activityStatus] = useQueryParam('status', StringParam);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [selectedSortValue, setSelectedSortValue] = useState<string>(MANAGER_SORT_FILTERS[0]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [isExportingCsv, setIsExportingCsv] = useState(false);
  const { selectedPrograms, getAuthorizedPrograms } = React.useContext(ProgramsContext);
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();
  const isActive = activityStatus !== 'active' || !activityStatus;
  const [queryActivities, { data: getActivities, loading: getActivityIdsIsLoading }] = useCampfireLazyQuery<
    GetActivitiesConsoleActivities,
    GetActivitiesConsoleActivitiesVariables
  >(GET_ACTIVITIES_CONSOLE_ACTIVITIES);

  const activityIds = useMemo(() => {
    const managedActivities = getActivities?.vm.programs.flatMap((program) => program.activities) ?? [];
    const ledActivities = getActivities?.vm.volunteer?.activityEnrolments.map((enrolment) => enrolment.activity) ?? [];
    const activities = uniqBy([...managedActivities, ...ledActivities], 'activityId');
    return activities.flatMap((activity) => activity.activityId);
  }, [getActivities]);

  const {
    data: getActivityCardData,
    loading: getActivitiesCardDataLoading,
    refetch: getActivitiesCardDataRefetch,
    refetchLoading: getActivitiesCardDataRefetchLoading,
  } = useCampfireQuery<GetActivitiesConsoleCardData, GetActivitiesConsoleCardDataVariables>(GET_ACTIVITIES_CARD_DATA, {
    options: {
      variables: {
        activityIds,
      },
    },
  });

  const { data: tagsData } = useCampfireQuery<GetActivityManagementActivityTags, {}>(
    GET_ACTIVITY_MANAGEMENT_ACTIVITY_TAGS
  );

  const [getActivityDataInfo, { loading: activityLoading, refetch: refetchActivity }] = useCampfireLazyQuery<
    GetActivityConsoleCardData,
    GetActivityConsoleCardDataVariables
  >(GET_ACTIVITY_CARD_DATA);
  const [activityInfo, setActivityInfo] = React.useState<IActivity>();

  React.useEffect(() => {
    if (selectedActivityId) {
      getActivityDataInfo({ variables: { activityId: selectedActivityId } }).then((response) => {
        if (response.data?.vm.activity) {
          setActivityInfo(response.data?.vm.activity);
        }
      });
    }
  }, [selectedActivityId]);

  const refetchSelectedActivity = () => {
    if (refetchActivity) {
      refetchActivity().then((response: any) => {
        if (response.data?.vm.activity) {
          setActivityInfo(response.data?.vm.activity);
        }
      });
    }
  };

  React.useEffect(() => {
    queryActivities({
      variables: {
        volunteerId,
        isActive,
        programIds: selectedPrograms.length > 0 ? selectedPrograms : undefined,
      },
    });
  }, []);

  useDeepEffect(() => {
    if (selectedPrograms.length > 0) {
      queryActivities({
        variables: {
          volunteerId,
          isActive,
          programIds: selectedProgramId && selectedActivityId !== 'all' ? [selectedProgramId] : undefined,
        },
      });
    }
  }, [volunteerId, isActive, selectedProgramId]);

  const activities = useMemo(() => {
    return getActivityCardData?.vm.activities ?? [];
  }, [getActivityCardData]);

  const activityTags = useMemo(() => {
    return tagsData?.vm.activityTags ?? [];
  }, [tagsData]);

  const programs = getAuthorizedPrograms();

  useEffect(() => {
    if (selectedPrograms.length === 1) {
      setSelectedProgramId(selectedPrograms[0]);
    } else if (!selectedProgramId) {
      setSelectedProgramId('all');
    }
  }, []);

  const handleExport = () => {
    setIsExportingCsv(true);
  };

  const handleCloseExportDialog = () => {
    setIsExportingCsv(false);
  };

  const handleSaveCsv = (activitiesExportData: ActivitiesConsoleCardExploreActivity[]) => {
    const rowsCsv = activitiesExportData.map((activity) => buildRowCsv(activity));
    const incidentCsvData = buildCsvData(rowsCsv);
    const filename = `activity_management_${DateTime.local().toFormat('yyyy-MM-d')}.csv`;
    downloadCSV(incidentCsvData, filename);
    setIsExportingCsv(false);
  };

  return (
    <PaneWrapper>
      {getActivityIdsIsLoading || getActivitiesCardDataLoading ? (
        <CommonScreenSkeleton />
      ) : !isMobile ? (
        <ActivitiesManagementNavItemsWrapper
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          selectedSortValue={selectedSortValue}
          setSelectedSortValue={setSelectedSortValue}
          setSearchInput={setSearchInput}
          handleExport={handleExport}
        >
          <Box
            width={'100%'}
            position={'relative'}
            display={isMd ? 'flex' : 'flex'}
            flexDirection={'row'}
            justifyContent={'flex-end'}
            height={'100%'}
            overflow='hidden'
          >
            <Box flexGrow={1} style={{ overflowY: 'auto' }} paddingLeft='60px'>
              {showMap ? (
                <ActivitiesMap
                  showSkinnyMarkers={false}
                  activities={activities}
                  selectedActivityId={selectedActivityId}
                  hoveredActivityId={hoveredActivityId}
                  setSelectedActivityId={(activityId) => setSelectedActivityId(activityId)}
                  setHoveredActivityId={(activityId) => setHoveredActivityId(activityId)}
                />
              ) : (
                <ActivitiesList
                  activities={activities}
                  isLoading={getActivityIdsIsLoading}
                  showFilters={showFilters}
                  programs={programs}
                  activityTags={activityTags}
                  selectedSortValue={selectedSortValue}
                  searchInput={searchInput}
                  isExportingCsv={isExportingCsv}
                  handleCloseExportDialog={handleCloseExportDialog}
                  handleSaveCsv={handleSaveCsv}
                />
              )}
            </Box>
            <CollapsibleSideBar>
              <SideBarActivityDetail
                activityId={selectedActivityId}
                activityInfo={activityInfo}
                activityTags={activityTags}
                getActivitiesCardDataLoading={
                  getActivitiesCardDataLoading || getActivityIdsIsLoading || activityLoading
                }
                getActivitiesCardDataRefetch={getActivitiesCardDataRefetch}
                getActivitiesCardDataRefetchLoading={getActivitiesCardDataRefetchLoading}
                refetchActivity={refetchSelectedActivity}
              />
            </CollapsibleSideBar>
          </Box>
        </ActivitiesManagementNavItemsWrapper>
      ) : (
        <ActivitiesManagementNavItemsWrapper
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          selectedSortValue={selectedSortValue}
          setSelectedSortValue={setSelectedSortValue}
          setSearchInput={setSearchInput}
          handleExport={handleExport}
        >
          <Box
            width={'100%'}
            position={'relative'}
            display={isMd ? 'auto' : 'flex'}
            flexDirection={isMd ? 'column' : 'row'}
            justifyContent={'flex-end'}
            height={'100%'}
            overflow='hidden'
          >
            <Box flexGrow={1} style={{ overflowY: 'auto', padding: '0 20px 0 20px' }}>
              <ActivitiesList
                activities={activities}
                isLoading={getActivityIdsIsLoading}
                showFilters={showFilters}
                programs={programs}
                activityTags={activityTags}
                selectedSortValue={selectedSortValue}
                searchInput={searchInput}
                isExportingCsv={isExportingCsv}
                handleCloseExportDialog={handleCloseExportDialog}
                handleSaveCsv={handleSaveCsv}
              />
            </Box>
          </Box>
          {activityInfo ? (
            <Dialog open={!!activityInfo} fullScreen={isMobile}>
              <Box p='24px'>
                <SideBarActivityDetail
                  activityId={selectedActivityId}
                  activityInfo={activityInfo}
                  activityTags={activityTags}
                  getActivitiesCardDataLoading={activityLoading}
                  getActivitiesCardDataRefetch={getActivitiesCardDataRefetch}
                  getActivitiesCardDataRefetchLoading={getActivitiesCardDataRefetchLoading}
                  refetchActivity={refetchSelectedActivity}
                />
              </Box>
            </Dialog>
          ) : (
            ''
          )}
        </ActivitiesManagementNavItemsWrapper>
      )}
    </PaneWrapper>
  );
};

export const ActivityManagementScreen = withRouter(({ match }) => {
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState<boolean>(false);

  return (
    <Page
      pageHelpOptions={{
        onClick: () => setTutorialDialogOpen(true),
      }}
    >
      <ProgramFiltersWrapper managementView>
        <CampfireSwitch>
          <Route
            exact
            path={match.path}
            render={() => (
              <ActivityManagementContextProvider>
                <ActivityManagementActivities />
              </ActivityManagementContextProvider>
            )}
          />
          <Route exact path={`${match.path}/create`} render={() => <CreateActivityScreenV2 />} />
          <Route exact path={`${match.path}/edit`} render={() => <EditActivityScreenV2 />} />
          <Route exact path={`${match.path}/duplicate`} render={() => <DuplicateActivityScreenV2 />} />
        </CampfireSwitch>
      </ProgramFiltersWrapper>
      <ActivityManagementTutorialDialog
        open={tutorialDialogOpen}
        onClose={() => {
          setTutorialDialogOpen(false);
        }}
      />
    </Page>
  );
});
