import { TitularTooltip } from '@campfire/titular-tooltip';
import { ListItem, Box, Typography } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import { DateInfoCard } from '../../../../common/cards/date-info-card/DateInfoCard';
import { QueryParams, RosterStatus, RosterListItem } from '../main-section/roster/roster-types';
import { getDisplayTimeSchedule } from '../../../../common/functions/activity-display-helpers';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { useSharedSessionResults } from '../main-section/roster/RosterHeader';
import { GET_ROSTER_ITEM_STATUS_DATA } from './roster-item-status-model.gql';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { getRosterStatus } from '../main-section/roster/roster-functions';
import { GetRosterItemStatusData, GetRosterItemStatusDataVariables } from './__generated__/GetRosterItemStatusData';
import { useSharedGlobalRefetch } from '../main-section/RosterMainSection';
import { useSharedPromptState } from '../main-section/roster/roster-table/RosterTableSharedState';

interface Props {
  roster: RosterListItem;
}

export const RosterItem = (props: Props) => {
  const { theme } = useCampfireTheme();
  const { roster } = props;

  const { data, loading, refetch } = useCampfireQuery<GetRosterItemStatusData, GetRosterItemStatusDataVariables>(
    GET_ROSTER_ITEM_STATUS_DATA,
    {
      options: {
        variables: {
          activityIds: [roster.activityId],
        },
      },
    }
  );

  const { globalRosterStatusRefetch } = useSharedGlobalRefetch();

  const [query, setQuery] = useQueryParams({
    activityDate: StringParam,
    activityId: StringParam,
    programId: StringParam,
    activityType: StringParam,
    activityStatus: StringParam,
    volunteersNeeded: NumberParam,
    startTime: StringParam,
    endTime: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    ot: StringParam,
  });

  const tooltipStatus = {
    published: 'Roster is published',
    draft: 'Draft exists',
    cancelled: 'Cancelled',
    neutral: 'Roster is not published',
    completed: 'Roster is completed',
    'not-published': 'Roster is completed but not published',
    'report-not-started': 'No reports available',
    'report-missing': 'Some reports are missing',
  };

  const activity = data?.vm.activities[0];

  const [rosterStatus, setRosterStatus] = useState<RosterStatus>('neutral');

  useEffect(() => {
    if (!loading && activity) {
      setRosterStatus(getRosterStatus(activity, roster));
      if (globalRosterStatusRefetch && refetch) {
        refetch();
      }
    }
  });

  const isSelected = (queryParams: QueryParams) =>
    queryParams.activityId === roster.activityId && queryParams.activityDate === roster.activityDate;

  const { resetPages } = useSharedSessionResults();
  const { setHidePromptForDate } = useSharedPromptState();

  return (
    <TitularTooltip title={tooltipStatus[rosterStatus]} placement={'left'}>
      <ListItem
        onClick={() => {
          setHidePromptForDate(false);
          setQuery({ activityId: roster.activityId, activityDate: roster.activityDate });
          resetPages();
        }}
        style={{
          cursor: 'pointer',
          paddingLeft: '0',
          paddingRight: '0',
        }}
      >
        <DateInfoCard
          date={roster.activityDate}
          color={theme.color.rosters.status[rosterStatus]}
          selected={isSelected(query)}
          size='s'
        >
          <Box display='flex' alignItems='center' justifyContent='space-between' alignSelf='stretch'>
            <Box display={'flex'} flexDirection={'column'} width={roster.volsNeeded < 0 ? '95%' : '100%'}>
              <Typography
                noWrap
                style={{
                  color: theme.color.grey.neutral400,
                  fontSize: '20px',
                  fontWeight: 800,
                  letterSpacing: '-0.02em',
                }}
              >
                {roster.activityName}
              </Typography>
              <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200, paddingTop: '6px' }}>
                {`${getDisplayTimeSchedule(roster.startTime, roster.endTime)}`}
              </Typography>
            </Box>
            {roster.volsNeeded > 0 && (rosterStatus === 'neutral' || rosterStatus === 'published') ? (
              <WarningRounded style={{ color: '#FBBF24' }} />
            ) : roster.activityStatus !== 'active' &&
              roster.activityStatus === 'completed' &&
              rosterStatus === 'report-missing' ? (
              <WarningRounded style={{ color: '#FBBF24' }} />
            ) : null}
          </Box>
        </DateInfoCard>
      </ListItem>
    </TitularTooltip>
  );
};
