import { gql } from '@apollo/client';
import {
  COMPLETED_TASK_FRAGMENT,
  TASK_FORM_ITEM_FRAGMENT,
} from '../../../../common/form/task-form/model/task-items.gql';

export const GET_MY_TASKS = gql`
  query GetMyTasks($userId: String!, $bulletinView: String = "all") {
    vm {
      profile(userId: $userId) {
        ...MyProfile
      }
      cakes {
        title
        description
        order
        tasks(view: $bulletinView) {
          ...MyTask
        }
      }
    }
  }
  fragment MyProfile on VOLUNTEER_ProfileType {
    profileId
    preferredName
    firstName
    lastName
    contactNumber
    dateOfBirth
    programs {
      name
    }
    completedTasks {
      ...CompletedTask
    }
  }
  fragment MyTask on VOLUNTEER_TaskType {
    taskId
    title
    description
    order
    dateRemoved
    allowUpdate
    taskItems {
      ...TaskFormItem
    }
  }
  ${COMPLETED_TASK_FRAGMENT}
  ${TASK_FORM_ITEM_FRAGMENT}
`;
