import { gql } from '@apollo/client';

export const GET_APPLICATION_SCREEN = gql`
  query GetApplicationScreen($inviteeId: String) {
    orgName
    vm {
      screeningQuestions {
        screeningQuestionId
        label
        order
      }
      invitee(inviteeId: $inviteeId) {
        inviteeId
        email
      }
    }
  }
`;

export interface ApplicationFormFormik {
  name: string;
  email: string;
  password: string;
  screeningAnswers: Array<{
    screeningQuestionId: string;
    answer: string;
  }>;
}
