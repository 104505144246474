import React, { memo, useEffect, useMemo } from 'react';
import { Grid, List, ListItem } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { cloneDeep, set, uniqBy } from 'lodash';
import { useHistory } from 'react-router';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { object as YupObject, string as YupString, array as YupArray } from 'yup';
import { DateTime } from 'luxon';
import { encodeDate, unpackToDate } from '@campfire/hot-date';
import { volunteerProfileHardCodedFields } from '../../../../../../common/form/task-form/model/get-volunteer-profile-hardcoded-field-values';
import { Recurrence } from '../../../../../../common/recurrence/__generated__/Recurrence';
import { assertNever } from '../../../../../../common/functions/assert-never';
import { arrayHead } from '../../../../../../common/functions/array-head';

import { useCampfireLazyQuery } from '../../../../../../global/network/useCampfireLazyQuery';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { useAdminConsoleActions } from '../../../admin-console-actions';
import {
  CakeSelectItems,
  DraggableTaskItemContent,
  TaskFormType,
  ExpiryRecurrence,
} from '../admin-console-volunteer-profile-model';
import {
  ADMIN_CONSOLE_GET_SINGLE_VOLUNTEER_PROFILE_TASK,
  ADMIN_CONSOLE_VOLUNTEER_PROFILE_TASK_BUILDER_GET_ALL_PROGRAMS,
  ADMIN_CONSOLE_VOLUNTEER_PROFILE_TASK_BUILDER_GET_ALL_ROLES,
} from '../admin-console-volunteer-profile.gql';
import { useLoadingContext } from '../AdminConsoleLoadingContext';
import { AdminConsoleVolunteerProfileHeader } from '../AdminConsoleVolunteerProfileHeader';
import {
  AdminConsoleGetSingleVolunteerProfileTask,
  AdminConsoleGetSingleVolunteerProfileTaskVariables,
} from '../__generated__/AdminConsoleGetSingleVolunteerProfileTask';
import { AdminConsoleVolunteerProfileTaskBuilderGetAllPrograms } from '../__generated__/AdminConsoleVolunteerProfileTaskBuilderGetAllPrograms';
import { Task_taskItems as TaskItems } from '../__generated__/Task';
import { FormBuilderDialogProvider } from './FormBuilderDialogContext';
import { FormBuilderHeader } from './FormBuilderHeader';
import { TaskControlsCard, TaskControlsContent } from './TaskControlsCard';
import { TaskDetailsCard } from './task-details-card/TaskDetailsCard';
import { AdminConsoleVolunteerProfileTaskBuilderGetAllRoles } from '../__generated__/AdminConsoleVolunteerProfileTaskBuilderGetAllRoles';
import { deserializeTaskItems, TaskItemsDeserialize } from './utils';
import { HardCodedFieldTaskItem } from './task-list/task-items/task-item-fields/HardCodedFieldTaskItem';
import { TaskListItemsForm } from './task-list/TaskListItemsForm';

const createTaskItem = (taskItemId: string, values: TaskItemsDeserialize) => {
  const taskItem = cloneDeep(values.byId[taskItemId]);

  if (taskItem.taskItemId.startsWith('new')) {
    set(taskItem, 'taskItemId', undefined);
  }

  if (taskItem.__typename === 'VOLUNTEER_TaskItemContentType') {
    set(
      taskItem,
      'attachments',
      taskItem.attachments.map((attachment) => ({
        ...attachment,
        attachmentId: attachment.attachmentId.includes('new') ? undefined : attachment.attachmentId,
      }))
    );
  }

  if (taskItem.__typename !== 'VOLUNTEER_TaskItemFieldType') {
    return taskItem;
  }

  if (taskItem.field.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldType') {
    set(
      taskItem,
      'field.multipleChoiceOptions',
      taskItem.field.multipleChoiceOptions.map((option) => ({
        ...option,
        multipleChoiceFieldOptionId: option.multipleChoiceFieldOptionId.includes('new')
          ? undefined
          : option.multipleChoiceFieldOptionId,
        trigger: {
          ...option.trigger,
          items: (values.byTrigger[option.multipleChoiceFieldOptionId]?.triggerItemIds || []).map(
            (itemId, itemIndex) => ({
              item: createTaskItem(itemId, values),
              order: itemIndex,
            })
          ),
        },
      }))
    );
  }

  if (taskItem.field.__typename === 'VOLUNTEER_CheckboxTaskFieldType') {
    set(
      taskItem,
      'field.checkboxOptions',
      taskItem.field.checkboxOptions.map((option) => ({
        ...option,
        checkboxFieldOptionId: option.checkboxFieldOptionId.includes('new') ? undefined : option.checkboxFieldOptionId,
        trigger: {
          ...option.trigger,
          items: (values.byTrigger[option.checkboxFieldOptionId]?.triggerItemIds || []).map((itemId, itemIndex) => ({
            item: createTaskItem(itemId, values),
            order: itemIndex,
          })),
        },
      }))
    );
  }

  if (taskItem.field.__typename === 'VOLUNTEER_DropdownTaskFieldType') {
    set(
      taskItem,
      'field.dropdownOptions',
      taskItem.field.dropdownOptions.map((option) => ({
        ...option,
        dropdownFieldOptionId: option.dropdownFieldOptionId.includes('new') ? undefined : option.dropdownFieldOptionId,
        trigger: {
          ...option.trigger,
          items: (values.byTrigger[option.dropdownFieldOptionId]?.triggerItemIds || []).map((itemId, itemIndex) => ({
            item: createTaskItem(itemId, values),
            order: itemIndex,
          })),
        },
      }))
    );
  }

  if (taskItem.field.__typename === 'VOLUNTEER_QuizTaskFieldType') {
    const correctId = taskItem.field.quizTaskFieldCorrect?.quizTaskFieldOption?.quizTaskFieldOptionId;
    set(
      taskItem,
      'field.quizTaskFieldOptions',
      taskItem.field.quizTaskFieldOptions.map((option) => ({
        ...option,
        isCorrect: option.quizTaskFieldOptionId === correctId,
        quizTaskFieldOptionId: option.quizTaskFieldOptionId.includes('new') ? undefined : option.quizTaskFieldOptionId,
        trigger: {
          ...option.trigger,
          items: (values.byTrigger[option.quizTaskFieldOptionId]?.triggerItemIds || []).map((itemId, itemIndex) => ({
            item: createTaskItem(itemId, values),
            order: itemIndex,
          })),
        },
      }))
    );
  }

  return taskItem;
};

const validationSchema = YupObject().shape({
  title: YupString().required('Task name is required'),
  cakeId: YupString().required('Please select a cake'),
  programIds: YupArray().required('Please select a program'),
});

export type LocationState = {
  state: {
    tab?: string;
  };
};
type ExtendedTaskItems = TaskItems | DraggableTaskItemContent;
interface Props {
  cakeSelectItems: CakeSelectItems;
  remainingManualTasks: number;
  refetch?: () => Promise<{ data: any }>;
}

export const TaskFormBuilder = memo((props: Props) => {
  const { cakeSelectItems, refetch, remainingManualTasks } = props;
  const { theme, isMobile } = useCampfireTheme();

  const [query] = useQueryParams({
    tid: StringParam,
    cid: StringParam,
    duplicate: NumberParam,
  });

  const { tid, duplicate, cid } = query;
  const { runSaveTask, runSaveTaskIsLoading } = useAdminConsoleActions();
  const { setIsLoading } = useLoadingContext();
  const history = useHistory();

  const [getTask, { data, loading }] = useCampfireLazyQuery<
    AdminConsoleGetSingleVolunteerProfileTask,
    AdminConsoleGetSingleVolunteerProfileTaskVariables
  >(ADMIN_CONSOLE_GET_SINGLE_VOLUNTEER_PROFILE_TASK);

  const { data: getAllProgramsData, loading: getAllProgramsLoading } = useCampfireQuery<
    AdminConsoleVolunteerProfileTaskBuilderGetAllPrograms,
    undefined
  >(ADMIN_CONSOLE_VOLUNTEER_PROFILE_TASK_BUILDER_GET_ALL_PROGRAMS);

  const { data: getAllRolesData, loading: getAllRolesLoading } = useCampfireQuery<
    AdminConsoleVolunteerProfileTaskBuilderGetAllRoles,
    undefined
  >(ADMIN_CONSOLE_VOLUNTEER_PROFILE_TASK_BUILDER_GET_ALL_ROLES);

  const permissionLevelOptions = [
    { label: 'All Permission Levels', value: 'none' },
    { label: 'General Volunteer', value: 'gv' },
    { label: 'Activity Leader', value: 'al' },
    { label: 'Program Manager', value: 'pm' },
    { label: 'Admin', value: 'admin' },
  ];

  const programsOfTask = data?.vm.task?.programs ?? [];
  const activitiessOfTask = data?.vm.task?.activities ?? [];
  const rolesOfTask = data?.vm.task?.roles ?? [];

  const programOptions = useMemo(() => {
    const activePrograms = getAllProgramsData?.vm.programs.filter((program) => program.dateSuspended === null) ?? [];
    return uniqBy([...activePrograms, ...programsOfTask], 'programId').sort((a, b) => a.name.localeCompare(b.name));
  }, [getAllProgramsData, programsOfTask]);

  const roleOptions = useMemo(() => {
    const allRoles = getAllRolesData?.vm.activityRoles ?? [];
    return uniqBy([...allRoles, ...rolesOfTask], 'activityRoleId').sort((a, b) => a.name.localeCompare(b.name));
  }, [getAllProgramsData, rolesOfTask]);

  useEffect(() => {
    if (tid)
      getTask({
        variables: {
          taskId: tid,
        },
      });
  }, [tid]);

  useEffect(() => setIsLoading(loading || runSaveTaskIsLoading || getAllProgramsLoading || getAllRolesLoading), [
    loading,
    runSaveTaskIsLoading,
    getAllProgramsLoading,
    getAllRolesLoading,
  ]);

  useEffect(() => setIsLoading(loading || runSaveTaskIsLoading), [loading, runSaveTaskIsLoading]);

  const getFrequency = (dataGetTask: AdminConsoleGetSingleVolunteerProfileTask): ExpiryRecurrence | undefined => {
    const task = dataGetTask?.vm.task;
    if (!task) return undefined;

    const recurrence = arrayHead(task.expiryRecurrence?.recurrences || []);
    if (!recurrence) return undefined;

    if (recurrence.__typename === 'MonthlyByMonthDayRecurrenceType') {
      switch (recurrence.monthInterval) {
        case 1:
          return 'monthly';
        case 3:
          return 'quarterly';
        case 6:
          return 'half_annually';
        case 12:
          return 'annually';
        default:
          break;
      }
    }
    return undefined;
  };
  const getStartDateRecurrence = (dataGetTask: AdminConsoleGetSingleVolunteerProfileTask): Date | undefined => {
    const task = dataGetTask?.vm.task;
    if (!task) return undefined;
    const recurrence = arrayHead(task.expiryRecurrence?.recurrences || []);
    if (!recurrence) return undefined;
    return unpackToDate(recurrence.startDate).toJSDate();
  };

  const initValues: TaskFormType = useMemo(
    () => ({
      taskId: !duplicate ? tid ?? undefined : undefined,
      title: `${data?.vm.task?.title ?? 'New Task'}${tid && duplicate ? ' (Copy)' : ''}`,
      description: data?.vm.task?.description ?? '',
      cakeId: cid ?? data?.vm.task?.cake.cakeId ?? cakeSelectItems[0]?.value ?? '',
      order: data?.vm.task?.order ?? 0,
      allowUpdate: data?.vm.task?.allowUpdate ?? false,
      isHidden: data?.vm.task?.isHidden ?? false,
      applicantsOnly: data?.vm.task?.applicantsOnly ?? false,
      taskItems: deserializeTaskItems(data?.vm.task?.taskItems),
      automateApproval: data?.vm.task?.automateApproval ?? false,
      frequency: data ? getFrequency(data) : undefined,
      startDate: data ? getStartDateRecurrence(data) : undefined,
      roleIds: !roleOptions.length
        ? []
        : rolesOfTask.length > 0
        ? rolesOfTask.flatMap((role) => role.activityRoleId)
        : [],
      programIds: !programOptions.length
        ? []
        : programsOfTask.length > 0
        ? programsOfTask.flatMap((program) => program.programId)
        : ['all'],
      activityIds: activitiessOfTask.length > 0 ? activitiessOfTask.flatMap((activity) => activity.activityId) : [],
      permissionLevel: data?.vm.task?.permissionLevel ?? 'none',
    }),
    [data, tid, duplicate, cakeSelectItems, programOptions, roleOptions]
  );

  const isTaskZero = useMemo(() => data?.vm.task?.order === -1, [data]);

  const parseRecurrence = (values: TaskFormType): Recurrence | undefined => {
    const startDate = values.startDate ? DateTime.fromJSDate(values.startDate) : DateTime.local();

    const baseRecurrence = {
      startDate: encodeDate(startDate),
      endDate: null,
      humanReadable: '', // This is a dummy so we can reuse autogenerated type
    };
    if (!values.frequency) return undefined;

    if (values.frequency === 'monthly') {
      const monthDay = startDate.day;
      return {
        ...baseRecurrence,
        __typename: 'MonthlyByMonthDayRecurrenceType',
        monthDay,
        monthInterval: 1,
      };
    }
    if (values.frequency === 'quarterly') {
      const monthDay = startDate.day;
      return {
        ...baseRecurrence,
        __typename: 'MonthlyByMonthDayRecurrenceType',
        monthDay,
        monthInterval: 3,
      };
    }
    if (values.frequency === 'half_annually') {
      const monthDay = startDate.day;
      return {
        ...baseRecurrence,
        __typename: 'MonthlyByMonthDayRecurrenceType',
        monthDay,
        monthInterval: 6,
      };
    }
    if (values.frequency === 'annually') {
      const monthDay = startDate.day;
      return {
        ...baseRecurrence,
        __typename: 'MonthlyByMonthDayRecurrenceType',
        monthDay,
        monthInterval: 12,
      };
    }
    return assertNever(values.frequency);
  };

  async function handleSave(values: TaskFormType) {
    const orderedTaskItems = values.taskItems.allIds
      .filter((itemId) => !values.taskItems.byTriggerOption[itemId] || !itemId.startsWith('new'))
      .map((itemId) => createTaskItem(itemId, values.taskItems))
      .map((item, taskItemIndex) => set(item, 'order', taskItemIndex));
    const expiryRecurrence = values.frequency ? parseRecurrence(values) : undefined;
    const valuesForSubmission = { ...values, expiryRecurrences: expiryRecurrence ? [expiryRecurrence] : undefined };
    if (values.programIds?.includes('all')) valuesForSubmission.programIds = [];
    if (!valuesForSubmission.programIds || valuesForSubmission.programIds.length === 0)
      valuesForSubmission.activityIds = [];
    if (values.permissionLevel === 'none') valuesForSubmission.permissionLevel = undefined;

    runSaveTask({ ...valuesForSubmission, taskItems: orderedTaskItems }, () => {
      if (refetch)
        refetch().then((res) => {
          if (res.data) history.goBack();
        });
    });
  }

  return (
    <FormBuilderDialogProvider>
      <Formik enableReinitialize initialValues={initValues} validationSchema={validationSchema} onSubmit={handleSave}>
        {({ values }) => {
          const activityOptions =
            getAllProgramsData?.vm.programs
              .filter((program) => values.programIds?.includes(program.programId))
              .flatMap((program) =>
                program.activities.map(({ activityId, name }) => ({
                  programId: program.programId,
                  activityId,
                  name,
                }))
              ) || [];

          return (
            <Form style={{ width: '100%', backgroundColor: theme.palette.grey[100] }}>
              <AdminConsoleVolunteerProfileHeader>
                <FormBuilderHeader taskName={values.title} taskId={values.taskId} isTaskZero={values.order === -1} />
              </AdminConsoleVolunteerProfileHeader>

              <Grid container justify='center'>
                <Grid item xs={10} sm={6}>
                  <TaskDetailsCard
                    isNew={!tid}
                    cakeSelectItems={cakeSelectItems}
                    remainingManualTasks={remainingManualTasks}
                    isTaskZero={values.order === -1}
                    programOptions={programOptions}
                    activityOptions={activityOptions}
                    roleOptions={roleOptions}
                    permissionLevelOptions={permissionLevelOptions}
                  />
                  {isMobile && <TaskControlsContent />}
                  {isTaskZero ? (
                    <List style={{ padding: 0, marginTop: 8 }}>
                      {Object.keys(volunteerProfileHardCodedFields).map((key) => (
                        <ListItem style={{ padding: '8px 0px' }} key={volunteerProfileHardCodedFields[key].name}>
                          <HardCodedFieldTaskItem
                            __typename={volunteerProfileHardCodedFields[key].__typename}
                            name={volunteerProfileHardCodedFields[key].name}
                            description={volunteerProfileHardCodedFields[key].description}
                            optional={volunteerProfileHardCodedFields[key].optional}
                          />
                        </ListItem>
                      ))}
                    </List>
                  ) : null}
                  <TaskListItemsForm />
                </Grid>

                {!isMobile && (
                  <Grid item sm={3} style={{ paddingLeft: '16px' }}>
                    <TaskControlsCard />
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </FormBuilderDialogProvider>
  );
});
