import { gql } from '@apollo/client';
import {
  COMPLETED_TASK_FRAGMENT,
  TASK_FORM_ITEM_FRAGMENT,
} from '../../../../../common/form/task-form/model/task-items.gql';

export const GET_VOLUNTEER_TASKS_SECTION_TASK = gql`
  query GetVolunteerTasksSectionTask(
    $completedTaskId: String!
    $taskId: String!
    # $userId: String!
    $profileId: String!
  ) {
    vm {
      profile(profileId: $profileId) {
        userId
        preferredName
        firstName
        lastName
        contactNumber
        dateOfBirth
        programs {
          name
        }
        completedTasks {
          completedTaskId
          status
          task {
            title
            description
            cake {
              cakeType
            }
          }
        }
      }
      # application(userId: $userId) {
      #   relevantTasks {
      #     cake {
      #       cakeType
      #     }
      #   }
      # }
      completedTask(completedTaskId: $completedTaskId) {
        ...CompletedTask
      }
      task(taskId: $taskId) {
        taskId
        title
        description
        cake {
          title
        }
        taskItems {
          ...TaskFormItem
        }
      }
    }
  }

  ${COMPLETED_TASK_FRAGMENT}
  ${TASK_FORM_ITEM_FRAGMENT}
`;
