import { Button, Theme } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { AddVolunteerDialog } from '../main-section/roster/AddVolunteerDialog';
import { GET_ROSTER_SCREEN_PROGRAM_VOLUNTEERS_DATA } from '../main-section/roster/rosters-model.gql';
import {
  GetRosterScreenProgramVolunteersData,
  GetRosterScreenProgramVolunteersDataVariables,
} from '../main-section/roster/__generated__/GetRosterScreenProgramVolunteersData';
import { RosterContext } from './RosterContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'none',
      borderRadius: '4px',
      padding: '7px 12px',
      fontSize: '13px',
      minWidth: '45px',
      lineHeight: '19px',
      backgroundColor: theme.color.secondary.main900,
      marginTop: '1.5rem',
    },
  })
);

export function AddVolunteer() {
  const { volunteers, activity, addNewVolunteer } = React.useContext(RosterContext);
  const [openAddVolunteersDialog, setOpenAddVolunteersDialog] = React.useState(false);
  const classes = useStyles();
  const [getProgramVolunteersData, { data: programVolunteersData }] = useCampfireLazyQuery<
    GetRosterScreenProgramVolunteersData,
    GetRosterScreenProgramVolunteersDataVariables
  >(GET_ROSTER_SCREEN_PROGRAM_VOLUNTEERS_DATA);
  const programVolunteers = React.useMemo(() => programVolunteersData?.vm.program?.activeVolunteers ?? [], [
    programVolunteersData,
  ]);

  const programId = activity?.program.programId;

  React.useEffect(() => {
    if (programId) {
      getProgramVolunteersData({ variables: { programId } });
    }
  }, [programId]);

  return (
    <React.Fragment>
      <Button
        startIcon={<AddCircle style={{ fontSize: '18px' }} />}
        variant='contained'
        color='primary'
        onClick={() => setOpenAddVolunteersDialog(true)}
        className={classes.button}
        disableElevation
      >
        Add New Volunteer
      </Button>
      <AddVolunteerDialog
        open={openAddVolunteersDialog}
        close={() => setOpenAddVolunteersDialog(false)}
        onSubmit={addNewVolunteer}
        volunteers={programVolunteers}
        ignoredVolunteerIds={volunteers.map((v) => v.volunteerId)}
      />
    </React.Fragment>
  );
}
