import { unpackToDate } from '@campfire/hot-date';
import React, { useEffect, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { Box, Button, Typography, Badge } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useBetween } from 'use-between';
import classNames from 'classnames';
import { TabletButton } from '@campfire/tablet-button';
import {
  ChevronLeft,
  ChevronRight,
  FiberManualRecord,
  Clear,
  ExpandMore,
  SettingsBackupRestore,
  PersonAdd,
  PostAdd,
  InsertDriveFile,
  FileCopy,
  Print,
} from '@material-ui/icons';
import { GetRosterScreenRosterData_vm_activity_sessions_reportType as ReportType } from './__generated__/GetRosterScreenRosterData';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useStyles } from '../../styles';
import { useUnpublishRosterFetch } from './roster-functions';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { CurrentRosterState } from './roster-types';
import { GET_ROSTER_SCREEN_ACTIVITY_ENROLMENT_DATA } from './rosters-model.gql';
import {
  GetRosterScreenActivityEnrolmentData,
  GetRosterScreenActivityEnrolmentDataVariables,
} from './__generated__/GetRosterScreenActivityEnrolmentData';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import { SortVolsRowButton } from './SortVolsRowButton';
import { TooltipMenu } from '../../../../../common/tooltip-menu';
import { useCopyLinkAction } from '../../../../../common/CopyToClipboard';

export const EXPANDED_VIEW_COLUMN_NO = 5;
export const COLLAPSED_VIEW_COLUMN_NO = 3;

const useSessionResults = () => {
  const [sessionResultsStart, setSessionResultsStart] = useState<number>(0);
  const [sessionResultsEnd, setSessionResultsEnd] = useState<number>(COLLAPSED_VIEW_COLUMN_NO);
  const [sessionPage, setSessionPage] = useState<number>(1);
  const [columnSize, setColumnSize] = useState<number>(COLLAPSED_VIEW_COLUMN_NO);
  const [totalSessionPages, setTotalSessionPages] = useState<number>(0);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const resetPages = () => {
    setSessionPage(1);
  };

  return {
    sessionResultsStart,
    setSessionResultsStart,
    sessionResultsEnd,
    setSessionResultsEnd,
    sessionPage,
    setSessionPage,
    resetPages,
    isExpanded,
    setIsExpanded,
    columnSize,
    setColumnSize,
    totalSessionPages,
    setTotalSessionPages,
  };
};

export const useSharedSessionResults = () => useBetween(useSessionResults);

interface RosterHeaderProps {
  rosterSessions: Array<{
    __typename: 'VOLUNTEER_SessionType';
    sessionId: string;
    name: string;
    description: string;
    minVolunteers: number | null;
    maxVolunteers: number | null;
    startTime: any | null;
    endTime: any | null;
    reportType: ReportType | null;
    isMaxed?: boolean;
  }>;
  activityName: string;
  activityDate: string;
  activityStatus: string;
  activityId: string;
  hasPublishedRoster: boolean;
  isRosterCancelled?: boolean;
  isRosterCompleted?: boolean;
  currentRosterState: CurrentRosterState;
  setShowCancelActivityDialog: (x: boolean) => void;
  handleSuccessRefetch?: () => void;
  onAddVoluteer?: () => void;
  setSortedVolunteerIdsByEnrolmentDate: (x: string[] | undefined) => void;
  onShowRosterNotes: (type: string) => void;
  hasRosterNotes?: boolean;
  componentRef: React.RefObject<HTMLDivElement>;
}

export const RosterHeader = (props: RosterHeaderProps) => {
  const classes = useStyles();
  const { setSnackbar } = useSnackbar();
  const {
    activityName,
    activityDate,
    activityStatus,
    activityId,
    hasPublishedRoster,
    isRosterCancelled,
    isRosterCompleted,
    currentRosterState,
    hasRosterNotes,
    setShowCancelActivityDialog,
    handleSuccessRefetch,
    onAddVoluteer,
    setSortedVolunteerIdsByEnrolmentDate,
    onShowRosterNotes,
    componentRef,
  } = props;
  const { theme, isMd, isMobile } = useCampfireTheme();

  const {
    sessionResultsStart,
    setSessionResultsStart,
    sessionResultsEnd,
    setSessionResultsEnd,
    sessionPage,
    setSessionPage,
    columnSize,
    totalSessionPages,
  } = useSharedSessionResults();
  const { handleCopyLinkClick } = useCopyLinkAction();

  useEffect(() => {
    const firstPageIndex: number = (sessionPage - 1) * columnSize;
    const lastPageIndex: number = firstPageIndex + columnSize;

    setSessionResultsStart(firstPageIndex);
    setSessionResultsEnd(lastPageIndex);
  }, [sessionPage, columnSize]);

  const totalSessionPagesArray = Array(totalSessionPages).fill(0);
  const runUnpublishRoster = useUnpublishRosterFetch();
  const showAddVolunteer = !isRosterCancelled && !isRosterCompleted;
  const setPrevPage = () => {
    if (sessionPage > 1) {
      setSessionResultsStart(sessionResultsStart - columnSize);
      setSessionResultsEnd(sessionResultsEnd - columnSize);
      setSessionPage(sessionPage - 1);
    }
  };

  const setNextPage = () => {
    if (sessionPage <= totalSessionPages - 1) {
      setSessionResultsStart(sessionResultsStart + columnSize);
      setSessionResultsEnd(sessionResultsEnd + columnSize);
      setSessionPage(sessionPage + 1);
    }
  };

  const [optionOpen, setOptionOpen] = React.useState(false);

  const [getActivityEnrolments, { loading: activityEnrolmentsLoading }] = useCampfireLazyQuery<
    GetRosterScreenActivityEnrolmentData,
    GetRosterScreenActivityEnrolmentDataVariables
  >(GET_ROSTER_SCREEN_ACTIVITY_ENROLMENT_DATA, {
    onCompleted: (result) => {
      setSortedVolunteerIdsByEnrolmentDate(
        result.vm.activity?.activityEnrolments.length
          ? result.vm.activity?.activityEnrolments
              .filter((enrolment) => !enrolment.dateRemoved)
              .sort((a, b) => (unpackToDate(a.dateEnrolled) < unpackToDate(b.dateEnrolled) ? 1 : -1))
              .flatMap((enrolment) => enrolment.volunteer.volunteerId)
          : undefined
      );
    },
  });

  const showRosterNotesHandler = (type: string) => {
    setOptionOpen(false);
    onShowRosterNotes(type);
  };

  useEffect(() => {
    if (activityId)
      getActivityEnrolments({
        variables: {
          activityId,
        },
      });
  }, [activityId]);

  const runUnpublishActivity = () => {
    runUnpublishRoster
      .run({
        activityId,
        activityDate,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to unpublish roster',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Unpublished roster',
          variant: 'success',
        });
        if (handleSuccessRefetch) handleSuccessRefetch();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to unpublish roster',
          variant: 'error',
        })
      );
  };

  return (
    <Box style={{ boxSizing: 'border-box', marginRight: '30px' }}>
      <Box
        width='100%'
        display='flex'
        justifyContent='space-between'
        borderBottom='1px solid #dcdcdc'
        paddingBottom='10px'
        alignItems='center'
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: '20px',
                color: theme.color.grey.neutralBrand800,
              }}
            >
              {activityName}
            </Typography>
            <Typography
              style={{
                fontSize: '13px',
                color: theme.color.grey.neutralBrand800,
              }}
            >
              {DateTime.fromISO(activityDate).toFormat('d')} {DateTime.fromISO(activityDate).toFormat('LLLL y')}
            </Typography>
          </Box>
          {hasRosterNotes ? (
            <Badge
              onClick={() => onShowRosterNotes('view')}
              style={{
                backgroundColor: theme.color.grey.neutral200,
                color: '#ffffff',
                padding: '6px',
                marginLeft: '15px',
                borderRadius: '3px',
                height: 'fit-content',
                cursor: 'pointer',
              }}
            >
              <InsertDriveFile style={{ fontSize: '16px' }} />
              <Typography style={{ fontSize: '12px', paddingLeft: '4px', fontWeight: 500, paddingRight: '2px' }}>
                Note Added
              </Typography>
            </Badge>
          ) : null}
        </Box>
        <Box display={'flex'}>
          <SortVolsRowButton loading={activityEnrolmentsLoading} />
          <Box style={{ margin: '0 10px', borderLeft: '1px solid rgb(239, 239, 239)' }}></Box>
          {activityStatus !== 'cancelled' && (
            <Button
              classes={{ root: classNames(classes.button, classes.dangerButton) }}
              onClick={() => setShowCancelActivityDialog(true)}
            >
              <Clear style={{ fontSize: '18px' }} />
              <Typography className={classes.typography}>Cancel Activity</Typography>
            </Button>
          )}
          {(hasPublishedRoster || showAddVolunteer) && (
            <Box display='flex'>
              <TooltipMenu
                open={optionOpen}
                onClose={() => {
                  setOptionOpen(false);
                }}
                title={
                  <Box display='flex' flexDirection='column' alignItems='flex-start' style={{ padding: '0 5px' }}>
                    {hasPublishedRoster && currentRosterState === 'published' && (
                      <Button
                        className={classes.cancelledButton}
                        startIcon={<SettingsBackupRestore />}
                        onClick={runUnpublishActivity}
                      >
                        Unpublish
                      </Button>
                    )}
                    {showAddVolunteer && (
                      <Button className={classes.edit} startIcon={<PersonAdd />} onClick={onAddVoluteer}>
                        Add new volunteer
                      </Button>
                    )}
                    <Button
                      className={classes.edit}
                      onClick={() => showRosterNotesHandler('edit')}
                      startIcon={<PostAdd />}
                    >
                      {hasRosterNotes ? 'Edit' : 'Add'} roster note
                    </Button>
                    <Button className={classes.edit} onClick={handleCopyLinkClick} startIcon={<FileCopy />}>
                      Copy Link
                    </Button>
                    <ReactToPrint
                      pageStyle='@page { size: landscape; }'
                      trigger={() => (
                        <Button className={classes.edit} onClick={handleCopyLinkClick} startIcon={<Print />}>
                          Print
                        </Button>
                      )}
                      content={() => componentRef?.current}
                    />
                  </Box>
                }
              >
                <TabletButton
                  size='small'
                  variant='outlined'
                  color='primary'
                  aria-controls='options-menu'
                  aria-haspopup='true'
                  onClick={() => setOptionOpen(true)}
                  endIcon={<ExpandMore />}
                  style={{
                    height: '100%',
                    border: '1px solid #9e9e9e',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    padding: '2px 7px',
                    marginRight: '10px',
                    color: theme.color.grey.neutral300,
                  }}
                >
                  Options
                </TabletButton>
              </TooltipMenu>
            </Box>
          )}
          <Box display={totalSessionPages > 1 ? 'flex' : 'none'} alignItems={'center'}>
            <Button
              style={{
                border: '1px solid #dcdcdc',
                boxSizing: 'border-box',
                borderRadius: '4px',
                textTransform: 'none',
                color: theme.color.grey.neutral400,
                marginRight: '10px',
                padding: !isMobile || !isMd ? '7px 12px' : '7px 3px',
                display: isMobile ? 'none' : 'inherit',
                minWidth: '40px',
              }}
              onClick={() => setPrevPage()}
            >
              <ChevronLeft style={{ fontSize: '18px' }} />
            </Button>

            {totalSessionPagesArray.map((s, index) => {
              return (
                <FiberManualRecord
                  data-track={s}
                  style={{
                    fontSize: '18px',
                    color: index + 1 === sessionPage ? '#7047EB' : '#C4C4C4',
                  }}
                />
              );
            })}
            <Button
              style={{
                border: '1px solid #dcdcdc',
                boxSizing: 'border-box',
                borderRadius: '4px',
                textTransform: 'none',
                color: theme.color.grey.neutral400,
                marginLeft: '10px',
                padding: !isMobile || !isMd ? '7px 12px' : '7px 3px',
                display: isMobile ? 'none' : 'inherit',
                minWidth: '40px',
              }}
              onClick={() => setNextPage()}
            >
              <ChevronRight style={{ fontSize: '18px' }} />
            </Button>
          </Box>
        </Box>
      </Box>
      {/* <Grid container justify={'space-evenly'} spacing={1}>
        <Grid item style={{ width: ACTIVITY_TEAM_ROSTER }} />
        {rosterSessions.slice(sessionResultsStart, sessionResultsEnd).map((s) => (
          <Grid item xs style={{ textAlign: 'center' }}>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: '16px',
                padding: '7px 0 7px 0',
                color: '#1B1612',
              }}
            >
              {s.name}
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
                color: theme.color.grey.neutral300,
              }}
            >
              {unpackToTime(s.startTime).toFormat('h:mm a')} - {unpackToTime(s.endTime).toFormat('h:mm a')}
            </Typography>
            {s.isMaxed ? (
              <Box textAlign='center' paddingTop={'5px'} paddingBottom={'5px'}>
                <WarningRounded style={{ color: '#FBBF24' }} />
                <Typography style={{ fontSize: '12px', color: '#92400E' }}>Maximum Volunteers Reached</Typography>
              </Box>
            ) : (
              ''
            )}
          </Grid>
        ))}
      </Grid> */}
    </Box>
  );
};
