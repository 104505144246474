import { TabletButton } from '@campfire/tablet-button';
import { Box, FormControl, IconButton, MenuItem, Popover, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloudDownload, Email as EmailIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useUser } from '../../../../global/auth/useUser';
import { InvitationDialog } from '../../../admin/invitation/InvitationDialog';
import { PrimaryBorderInput } from '../../../../common/filter-fields/FilterClasses';
import { VolunteerDatabaseImpactFilter } from './VolunteerDatabaseImpactFilter';
import { VolunteerDatabaseTasksFilter } from './VolunteerDatabaseTasksFilter';
import { VolunteerDatabaseTasks } from './__generated__/VolunteerDatabaseTasks';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ExportsList } from '../../../../common/export/ExportsList';
import { useOrgInfo } from '../../../../global/auth/use-org-info/use-org-info';

type VolunteerDatabaseTableOptionsMenuProps = {
  setActiveVolsFilter: (x: boolean) => void;
  activeVolsFilter: boolean;
  tasks?: VolunteerDatabaseTasks[];
  setTasksFilter?: (x: any) => void;
  tasksFilter?: { [key: string]: boolean };
  impactOptions?: Array<{ name: string; label: string }>;
  setImpactFilter?: (x: any) => void;
  impactFilter?: { [key: string]: boolean };
  onRequestDownload?: () => void;
};

const useStyles = makeStyles((theme) => ({
  notXs: {
    display: 'inherit',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export const VolunteerDatabaseTableOptionsMenu = (props: VolunteerDatabaseTableOptionsMenuProps) => {
  const {
    activeVolsFilter,
    setActiveVolsFilter,
    tasks,
    setTasksFilter,
    tasksFilter,
    impactOptions,
    setImpactFilter,
    impactFilter,
    onRequestDownload,
  } = props;
  const [dialog, setDialog] = useState(false);
  const styles = useStyles();
  const {
    user: { userIdentityService },
  } = useUser();
  const { isVmAdmin } = userIdentityService;
  const { isMobile } = useCampfireTheme();
  const org = useOrgInfo();
  const orgHide = org?.tenantId !== '3d344ce4-7ef7-4777-99d2-9b3291cf85b4' && org?.tenantId !== '5fbb9c2c-c282-4048-b9ca-3c651f9a4346';
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setActiveVolsFilter(event.target.value === 'active');
  };

  const handleInvitationDialogClose = () => {
    setDialog(false);
  };

  const [exportTarget, setExportTarget] = React.useState<HTMLElement | null>(null);

  const onDownload = (e: React.MouseEvent<HTMLElement>) => {
    setExportTarget(e.currentTarget);
  };

  return (
    <>
      {isVmAdmin && (
        <InvitationDialog handleClose={handleInvitationDialogClose} open={dialog} onSuccess={() => setDialog(false)} />
      )}
      <Box width={1} display='flex' justifyContent='space-between' paddingX={1} style={{ boxSizing: 'border-box' }}>
        <Box>
          {isVmAdmin && isMobile && (
            <TabletButton
              data-track='fs-volDb-invite-volunteers-button'
              color='primary'
              variant='contained'
              size='small'
              onClick={() => setDialog(true)}
              startIcon={<EmailIcon fontSize='small' color='inherit' />}
            >
              {'Invite Volunteers'}
            </TabletButton>
          )}
        </Box>
        <Box display='flex' alignItems='center' className={styles.notXs}>
          {onRequestDownload && 
            orgHide ? (
            <div>
              <IconButton onClick={onDownload}>
                <CloudDownload />
              </IconButton>
              <Popover
                open={Boolean(exportTarget)}
                anchorEl={exportTarget}
                onClose={() => setExportTarget(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <ExportsList exportType='volunteer' onCreate={onRequestDownload} />
              </Popover>
            </div> ) : ''
          }
          <Box margin={0.5}>
            <VolunteerDatabaseImpactFilter
              impactOptions={impactOptions ?? []}
              setImpactFilter={setImpactFilter}
              impactFilter={impactFilter}
            />
          </Box>
          <Box margin={0.5} marginRight={1}>
            <VolunteerDatabaseTasksFilter
              tasks={tasks ?? []}
              setTasksFilter={setTasksFilter}
              tasksFilter={tasksFilter}
            />
          </Box>
          <Box mx={2}>
            <Typography color='textSecondary'>|</Typography>
          </Box>
          <Box mr={2}>
            <Typography variant='subtitle2' color='textSecondary'>
              Show:
            </Typography>
          </Box>
          <FormControl variant='outlined' style={{ marginRight: 4 }}>
            <Select
              value={activeVolsFilter ? 'active' : 'all'}
              onChange={handleChange}
              color='primary'
              input={<PrimaryBorderInput />}
            >
              <MenuItem value={'active'}>Active Volunteers</MenuItem>
              <MenuItem value={'all'}>All Volunteers</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </>
  );
};
