import { gql } from '@apollo/client';
import { RECURRENCE_FRAGMENT } from '../../../../../common/recurrence/recurrence-model.gql';

const CHECKBOX_TASK_FIELD_FRAGMENT = gql`
  fragment CheckboxTaskField on VOLUNTEER_CheckboxTaskFieldType {
    taskFieldId
    name
    description
    dynamic
    checkboxOptions {
      checkboxFieldOptionId
      name
      allowText
      order
      trigger {
        taskTriggerId
        items {
          taskTriggerItemId
          order
          item {
            ...on VOLUNTEER_TaskItemHeadingType {
              taskItemId
            }

            ...on VOLUNTEER_TaskItemFieldType {
              taskItemId
            }

            ...on VOLUNTEER_TaskItemContentType {
              taskItemId
            }

            ...on VOLUNTEER_TaskItemMediaType {
              taskItemId
            }
          }
        }
      }
    }
  }
`;

const DROPDOWN_TASK_FIELD_FRAGMENT = gql`
  fragment DropdownTaskField on VOLUNTEER_DropdownTaskFieldType {
    taskFieldId
    name
    description
    dynamic
    dropdownOptions {
      dropdownFieldOptionId
      name
      allowText
      order
      trigger {
        taskTriggerId
        items {
          taskTriggerItemId
          order
          item {
            ...on VOLUNTEER_TaskItemHeadingType {
              taskItemId
            }

            ...on VOLUNTEER_TaskItemFieldType {
              taskItemId
            }

            ...on VOLUNTEER_TaskItemContentType {
              taskItemId
            }

            ...on VOLUNTEER_TaskItemMediaType {
              taskItemId
            }
          }
        }
      }
    }
  }
`;

const MULTIPLE_CHOICE_TASK_FIELD_FRAGMENT = gql`
  fragment MultipleChoiceTaskField on VOLUNTEER_MultipleChoiceTaskFieldType {
    taskFieldId
    name
    description
    dynamic
    multipleChoiceOptions {
      multipleChoiceFieldOptionId
      name
      allowText
      order
      trigger {
        taskTriggerId
        items {
          taskTriggerItemId
          order
          item {
            ... on VOLUNTEER_TaskItemHeadingType {
              taskItemId
            }

            ... on VOLUNTEER_TaskItemFieldType {
              taskItemId
            }

            ... on VOLUNTEER_TaskItemContentType {
              taskItemId
            }

            ... on VOLUNTEER_TaskItemMediaType {
              taskItemId
            }
          }
        }
      }
    }
  }
`;
const QUIZ_TASK_FIELD_FRAGMENT = gql`
  fragment QuizTaskField on VOLUNTEER_QuizTaskFieldType {
    taskFieldId
    name
    description
    allowRetry
    dynamic
    quizTaskFieldOptions {
      quizTaskFieldOptionId
      name
      allowText
      order
      trigger {
        taskTriggerId
        items {
          taskTriggerItemId
          order
          item {
            ...on VOLUNTEER_TaskItemHeadingType {
              taskItemId
            }

            ...on VOLUNTEER_TaskItemFieldType {
              taskItemId
            }

            ...on VOLUNTEER_TaskItemContentType {
              taskItemId
            }

            ...on VOLUNTEER_TaskItemMediaType {
              taskItemId
            }
          }
        }
      }
    }
    quizTaskFieldCorrect {
      quizTaskFieldCorrectId
      correctText
      quizTaskFieldOption {
        quizTaskFieldOptionId
      }
    }
  }
`;

const ADDRESS_TASK_FIELD_FRAGMENT = gql`
  fragment AddressTaskField on VOLUNTEER_AddressTaskFieldType {
    taskFieldId
    name
    description
    allowManual
  }
`;

const ATTACHMENT_TASK_FIELD_FRAGMENT = gql`
  fragment AttachmentTaskField on VOLUNTEER_AttachmentTaskFieldType {
    taskFieldId
    name
    description
    allowMultiple
  }
`;

const EMAIL_TASK_FIELD_FRAGMENT = gql`
  fragment EmailTaskField on VOLUNTEER_EmailTaskFieldType {
    taskFieldId
    name
    description
  }
`;

const LONG_TEXT_TASK_FIELD_FRAGMENT = gql`
  fragment LongTextTaskField on VOLUNTEER_LongTextTaskFieldType {
    taskFieldId
    name
    description
  }
`;

const DATE_TASK_FIELD_FRAGMENT = gql`
  fragment DateTaskField on VOLUNTEER_DateTaskFieldType {
    taskFieldId
    name
    description
    isExpiry
  }
`;

const NUMBER_TASK_FIELD_FRAGMENT = gql`
  fragment NumberTaskField on VOLUNTEER_NumberTaskFieldType {
    taskFieldId
    name
    description
  }
`;

const PHONE_TASK_FIELD_FRAGMENT = gql`
  fragment PhoneTaskField on VOLUNTEER_PhoneTaskFieldType {
    taskFieldId
    name
    description
  }
`;

const SHORT_TEXT_TASK_FIELD_FRAGMENT = gql`
  fragment ShortTextTaskField on VOLUNTEER_ShortTextTaskFieldType {
    taskFieldId
    name
    description
  }
`;

const SIGNATURE_TASK_FIELD_FRAGMENT = gql`
  fragment SignatureTaskField on VOLUNTEER_SignatureTaskFieldType {
    taskFieldId
    name
    description
  }
`;

const TASK_ITEM_TRIGGER_FRAGMENT = gql`
  fragment TaskItemTrigger on VOLUNTEER_TaskTriggerItemType {
    taskTriggerItemId
    order
    trigger {
      taskTriggerId
      ... on VOLUNTEER_DynamicMultipleChoiceFieldTriggerType {
        triggerOption {
          multipleChoiceFieldOptionId
        }
      }
      ...on VOLUNTEER_DynamicCheckboxFieldTriggerType {
        triggerOption {
          checkboxFieldOptionId
        }
      }
      ...on VOLUNTEER_DynamicDropdownFieldTriggerType {
        triggerOption {
          dropdownFieldOptionId
        }
      }
      ...on VOLUNTEER_DynamicQuizFieldTriggerType {
        triggerOption {
          quizTaskFieldOptionId
        }
      }
    }
  }
`;

const TASK_ITEM_HEADING_FRAGMENT = gql`
  fragment TaskItemHeading on VOLUNTEER_TaskItemHeadingType {
    taskItemId
    order
    heading
    triggerItem {
      ...TaskItemTrigger
    }
  }
  ${TASK_ITEM_TRIGGER_FRAGMENT}
`;
const TASK_ITEM_CONTENT_ATTACHMENT = gql`
  fragment TaskItemContentAttachment on VOLUNTEER_TaskItemContentAttachmentType {
    attachmentId
    url
    name
    fileType
  }
`;
const TASK_ITEM_CONTENT_FRAGMENT = gql`
  fragment TaskItemContent on VOLUNTEER_TaskItemContentType {
    taskItemId
    order
    content
    triggerItem {
      ...TaskItemTrigger
    }
    attachments {
      ...TaskItemContentAttachment
    }
  }
  ${TASK_ITEM_CONTENT_ATTACHMENT}
  ${TASK_ITEM_TRIGGER_FRAGMENT}
`;

const TASK_ITEM_FIELD_FRAGMENT = gql`
  fragment TaskItemField on VOLUNTEER_TaskItemFieldType {
    taskItemId
    order
    optional
    triggerItem {
      ...TaskItemTrigger
    }
    field {
      ...CheckboxTaskField
      ...DropdownTaskField
      ...MultipleChoiceTaskField
      ...AddressTaskField
      ...AttachmentTaskField
      ...EmailTaskField
      ...LongTextTaskField
      ...DateTaskField
      ...NumberTaskField
      ...PhoneTaskField
      ...ShortTextTaskField
      ...QuizTaskField
      ...SignatureTaskField
    }
  }
  ${CHECKBOX_TASK_FIELD_FRAGMENT}
  ${DROPDOWN_TASK_FIELD_FRAGMENT}
  ${MULTIPLE_CHOICE_TASK_FIELD_FRAGMENT}
  ${ADDRESS_TASK_FIELD_FRAGMENT}
  ${ATTACHMENT_TASK_FIELD_FRAGMENT}
  ${EMAIL_TASK_FIELD_FRAGMENT}
  ${LONG_TEXT_TASK_FIELD_FRAGMENT}
  ${DATE_TASK_FIELD_FRAGMENT}
  ${NUMBER_TASK_FIELD_FRAGMENT}
  ${PHONE_TASK_FIELD_FRAGMENT}
  ${SHORT_TEXT_TASK_FIELD_FRAGMENT}
  ${QUIZ_TASK_FIELD_FRAGMENT}
  ${TASK_ITEM_TRIGGER_FRAGMENT}
  ${SIGNATURE_TASK_FIELD_FRAGMENT}
`;

const YOUTUBE_MEDIA_FRAGMENT = gql`
  fragment YoutubeMedia on VOLUNTEER_YoutubeMediaType {
    name
    description
    videoId
  }
`;

const VIMEO_MEDIA_FRAGMENT = gql`
  fragment VimeoMedia on VOLUNTEER_VimeoMediaType {
    name
    description
    videoId
  }
`;

const TASK_ITEM_MEDIA_FRAGMENT = gql`
  fragment TaskItemMedia on VOLUNTEER_TaskItemMediaType {
    taskItemId
    order
    media {
      ...YoutubeMedia
      ...VimeoMedia
    }
    triggerItem {
      ...TaskItemTrigger
    }
  }
  ${YOUTUBE_MEDIA_FRAGMENT}
  ${VIMEO_MEDIA_FRAGMENT}
  ${TASK_ITEM_TRIGGER_FRAGMENT}
`;

const TASK_FRAGMENT = gql`
  fragment Task on VOLUNTEER_PermissionlessTaskType {
    taskId
    title
    description
    order
    automateApproval
    allowUpdate
    isHidden
    applicantsOnly
    programs {
      programId
      name
      dateSuspended
    }
    activities {
      activityId
      name
    }
    roles {
      activityRoleId
      name
    }
    permissionLevel
    taskItems {
      ...TaskItemHeading
      ...TaskItemContent
      ...TaskItemField
      ...TaskItemMedia
    }
    cake {
      cakeId
      title
    }
    hasRules
    expiryRecurrence {
      recurrences {
        ...Recurrence
      }
    }
  }

  ${TASK_ITEM_HEADING_FRAGMENT}
  ${TASK_ITEM_CONTENT_FRAGMENT}
  ${TASK_ITEM_FIELD_FRAGMENT}
  ${TASK_ITEM_MEDIA_FRAGMENT}
  ${RECURRENCE_FRAGMENT}
`;

const CAKE_FRAGMENT = gql`
  fragment Cake on VOLUNTEER_CakeType {
    cakeId
    title
    description
    required
    order
    cakeType
    allTasks {
      ...Task
    }
  }
  ${TASK_FRAGMENT}
`;

export const ADMIN_CONSOLE_GET_SINGLE_VOLUNTEER_PROFILE_TASK = gql`
  query AdminConsoleGetSingleVolunteerProfileTask($taskId: String!) {
    vm {
      task(taskId: $taskId) {
        ...Task
      }
    }
  }
  ${TASK_FRAGMENT}
`;

export const ADMIN_CONSOLE_GET_VOLUNTEER_PROFILE_TASKS = gql`
  query AdminConsoleGetVolunteerProfileTasks {
    vm {
      cakes {
        ...Cake
      }
    }
  }
  ${CAKE_FRAGMENT}
`;

export const ADMIN_CONSOLE_VOLUNTEER_PROFILE_TASK_BUILDER_GET_ALL_PROGRAMS = gql`
  query AdminConsoleVolunteerProfileTaskBuilderGetAllPrograms {
    vm {
      programs {
        programId
        name
        dateSuspended
        activities {
          activityId
          name
        }
      }
    }
  }
`;

export const ADMIN_CONSOLE_VOLUNTEER_PROFILE_TASK_BUILDER_GET_ALL_ROLES = gql`
  query AdminConsoleVolunteerProfileTaskBuilderGetAllRoles {
    vm {
      activityRoles {
        activityRoleId
        name
      }
    }
  }
`;
