import classnames from 'classnames';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { memo, ReactNode } from 'react';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

type IncomingVolunteerTaskListItemType = {
  taskId: string;
  icon: ReactNode;
  taskTitle: string;
  taskSubText?: string;
  taskRecentActivityStatus?: string;
  onClick?: () => void;
  disabled?: boolean;
  selected?: boolean;
};

const useStyles = () => {
  const { theme, isMobile } = useCampfireTheme();
  return makeStyles({
    listItem: {
      padding: isMobile ? '8px 32px' : '8px 48px',
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    taskListItem: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    taskListItemHover: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
      },
    },
    taskListDisabled: {
      opacity: '0.5',
      cursor: 'default',
    },
    taskListItemSelected: {
      backgroundColor: theme.palette.grey[200],
    },
    taskListSubItem: {
      padding: isMobile ? '8px 40px' : '8px 64px',
    },
    iconContainer: {
      marginRight: isMobile ? 16 : 8,
    },
    borderBottom: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    borderRight: {
      borderRight: `1px solid ${theme.color.grey.border}`,
    },
  })();
};

type IncomingVolunteerTaskListItemProps = IncomingVolunteerTaskListItemType & { locked?: boolean };

const IncomingVolunteerTaskListItem = memo(
  ({
    icon,
    taskTitle,
    taskSubText,
    taskRecentActivityStatus,
    onClick,
    locked,
    disabled,
    selected,
  }: IncomingVolunteerTaskListItemProps) => {
    const classes = useStyles();

    return (
      <Box
        display='flex'
        alignContent='center'
        alignItems='center'
        width={1}
        paddingX={1}
        className={`${classes.taskListItem} ${
          disabled || locked ? classes.taskListDisabled : classes.taskListItemHover
        } ${selected ? classes.taskListItemSelected : ''}`}
        onClick={() => (!locked && !disabled && onClick ? onClick() : null)}
      >
        <Box
          display='flex'
          alignContent='center'
          alignItems='center'
          flex={2}
          className={classes.borderRight}
          paddingY={1}
        >
          <Box paddingX={2}>{icon}</Box>
          <Box>
            <span>
              <Typography display='inline' variant='body2' style={{ marginRight: 12 }}>
                {taskTitle}
              </Typography>
              {taskSubText && (
                <Typography display='inline' variant='caption' color='textSecondary'>
                  {taskSubText}
                </Typography>
              )}
            </span>
          </Box>
        </Box>
        <Box display='flex' alignContent='center' alignItems='center' flex={1} paddingLeft={2}>
          <Typography variant='caption' color='textSecondary'>
            {taskRecentActivityStatus}
          </Typography>
        </Box>
      </Box>
    );
  }
);

const IncomingVolunteerTaskListItemMobile = memo(
  ({
    icon,
    taskTitle,
    taskSubText,
    taskRecentActivityStatus,
    onClick,
    locked,
  }: {
    icon: ReactNode;
    taskTitle: string;
    taskSubText?: string;
    taskRecentActivityStatus?: string;
    onClick?: () => void;
    locked?: boolean;
  }) => {
    const classes = useStyles();
    return (
      <Grid
        item
        container
        wrap='nowrap'
        alignItems='center'
        className={classnames(classes.taskListItem, classes.taskListSubItem)}
        onClick={() => (!locked && onClick ? onClick() : null)}
      >
        <Grid item className={classes.iconContainer}>
          {icon}
        </Grid>
        <Grid item container direction='column'>
          <Typography variant='body2'>{taskTitle}</Typography>
          {taskSubText && (
            <Grid item style={{ flex: 1 }}>
              <Typography variant='caption' color='textSecondary'>
                {taskSubText}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Typography variant='caption' color='textSecondary'>
              {taskRecentActivityStatus}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export const IncomingVolunteerTaskList = ({
  header,
  locked,
  items,
}: {
  header: string;
  locked?: boolean;
  items: Array<IncomingVolunteerTaskListItemType>;
}) => {
  const classes = useStyles();
  const { isMobile } = useCampfireTheme();
  return (
    <Grid container style={{ opacity: locked ? 0.5 : 1 }}>
      <Grid item container alignItems='center' className={classes.listItem}>
        <Grid item>
          <Typography variant='h6' style={{ fontWeight: 'bold' }}>
            {header}
          </Typography>
        </Grid>
      </Grid>
      {items.map((item) =>
        isMobile ? (
          <IncomingVolunteerTaskListItemMobile
            key={item.taskId}
            icon={item.icon}
            taskTitle={item.taskTitle}
            taskSubText={item.taskSubText}
            taskRecentActivityStatus={item.taskRecentActivityStatus}
            onClick={item.onClick}
            locked={locked}
          />
        ) : (
          <IncomingVolunteerTaskListItem
            key={item.taskId}
            icon={item.icon}
            taskId={item.taskId}
            taskTitle={item.taskTitle}
            taskSubText={item.taskSubText}
            taskRecentActivityStatus={item.taskRecentActivityStatus}
            onClick={item.onClick}
            locked={locked}
            disabled={item.disabled}
            selected={item.selected}
          />
        )
      )}
    </Grid>
  );
};
