import { gql } from '@apollo/client';

const INCOMING_VOLUNTEER_TASK_FRAGMENT = gql`
  fragment IncomingVolunteerTask on VOLUNTEER_ApplicantTaskType {
    taskId
    title
    description
    order
    cake {
      cakeId
      cakeType
    }
    taskItems {
      ... on VOLUNTEER_TaskItemHeadingType {
        taskItemId
      }
      ... on VOLUNTEER_TaskItemContentType {
        taskItemId
      }
      ... on VOLUNTEER_TaskItemFieldType {
        taskItemId
        optional
        field {
          taskFieldId
        }
      }
    }
    dateRemoved
    dateLastUpdated
    automateApproval
  }
`;

const INCOMING_VOLUNTEER_INVITEE_DETAIL_FRAGMENT = gql`
  fragment IncomingVolunteerInviteeDetail on VOLUNTEER_VolunteerInviteeType {
    __typename
    inviteeId
    email
    dateInvited
    dateRevoked
    invitedBy {
      profile {
        profileId
        preferredName
        lastName
        email
      }
    }
    dateAccepted
  }
`;

const INCOMING_VOLUNTEER_APPLICATION_DETAIL_FRAGMENT = gql`
  fragment IncomingVolunteerApplicationDetail on VOLUNTEER_ApplicationType {
    applicationId
    profile {
      profileId
      userId
    }
    status
    name
    email
    dateSubmitted
    mostRecentReminder {
      applicationReminderId
      remindedAt
    }
    relevantTasks {
      ...IncomingVolunteerTask
    }
    screeningAnswers {
      screeningAnswerId
      screeningQuestion {
        screeningQuestionId
        label
        order
        correctAnswer
      }
      answer
      isCorrect
    }
    invitee {
      ...IncomingVolunteerInviteeDetail
    }
  }
  ${INCOMING_VOLUNTEER_INVITEE_DETAIL_FRAGMENT}
  ${INCOMING_VOLUNTEER_TASK_FRAGMENT}
`;

const INCOMING_VOLUNTEER_COMPLETED_TASK_FRAGMENT = gql`
  fragment IncomingVolunteerCompletedTask on VOLUNTEER_CompletedTaskType {
    completedTaskId
    status
    dateLastUpdated
    task {
      __typename
      taskId
      cake {
        cakeType
      }
      dateLastUpdated
      dateRemoved
    }
  }
`;

export const GET_INCOMING_VOLUNTEER_PROFILE = gql`
  query GetIncomingVolunteerProfile($profileId: String!) {
    vm {
      profile(profileId: $profileId) {
        profileId
        userId
        preferredName
        lastName
        email
        isCompleted
        dateRemoved
        isProfileTaskApproved
        percentageComplete
        dateLastUpdated
        completedTasks {
          ...IncomingVolunteerCompletedTask
        }
        application {
          ...IncomingVolunteerApplicationDetail
        }
      }
    }
  }
  ${INCOMING_VOLUNTEER_COMPLETED_TASK_FRAGMENT}
  ${INCOMING_VOLUNTEER_APPLICATION_DETAIL_FRAGMENT}
`;

export const GET_INCOMING_VOLUNTEER_ALL_TASKS = gql`
  query GetIncomingVolunteerAllTasks($userId: String!) {
    vm {
      application(userId: $userId) {
        relevantTasks {
          ...IncomingVolunteerTask
        }
      }
    }
  }

  ${INCOMING_VOLUNTEER_TASK_FRAGMENT}
`;

export const GET_INCOMING_VOLUNTEER_INVITEE_DETAIL = gql`
  query GetIncomingVolunteerInviteeDetail($inviteeId: String!) {
    vm {
      invitee(inviteeId: $inviteeId) {
        ...IncomingVolunteerInviteeDetail
      }
    }
  }

  ${INCOMING_VOLUNTEER_INVITEE_DETAIL_FRAGMENT}
`;

export const GET_INCOMING_VOLUNTEER_APPLICATION_DETAIL = gql`
  query GetIncomingVolunteerApplicationDetail($applicationId: String!) {
    vm {
      application(applicationId: $applicationId) {
        ...IncomingVolunteerApplicationDetail
      }
    }
  }

  ${INCOMING_VOLUNTEER_APPLICATION_DETAIL_FRAGMENT}
`;

export type LeftMenuItemType =
  | {
      inviteeId: string;
      type: 'VOLUNTEER_VolunteerInviteeType';
    }
  | { applicationId: string; type: 'VOLUNTEER_ApplicationType' }
  | {
      taskId: string;
      type: 'VOLUNTEER_TaskType' | 'VOLUNTEER_PermissionlessTaskType' | 'VOLUNTEER_ApplicantTaskType';
      completedTaskId?: string;
    };
