import React from 'react';
import { ClickAwayListener, Tooltip, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: theme.color.tooltip.background,
      borderRadius: 16,
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      padding: 4,
    },
    arrow: {
      '&::before': {
        backgroundColor: theme.color.tooltip.background,
      },
    },
  })
);

interface TooltipMenuProps {
  children: React.ReactElement;
  title: React.ReactElement;
  open: boolean;
  onClose: () => void;
}

export function TooltipMenu(props: TooltipMenuProps) {
  const { open, onClose, children, title } = props;

  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        <Tooltip
          interactive
          title={title}
          disableHoverListener
          disableFocusListener
          disableTouchListener
          open={open}
          onClose={onClose}
          classes={classes}
          arrow
        >
          {children}
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}
